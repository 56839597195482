
import eventBox from "./eventBox";
import TimeLine from "./TimeLine";
import ctese15rp86ll6q9w1vi from './ctese15rp86ll6q9w1vi/ctese15rp86ll6q9w1vi.vue';
export default {
  name: "track-timeline",
  components: { eventBox,ctese15rp86ll6q9w1vi },
  props:{
    timelineToEl:String,
  },
  data(){
    return {
      timeLineData:[],
      trackLayer:null,
      datetime:null,
      track_res:{},
    }
  },

  created() {

  },
  mounted() {
    vuecc.bus.$on("activeTimeLine",(index)=>{
      this.activeTimeLine(index);
    })
    setTimeout(()=>{
      document.querySelector('#'+this.timelineToEl).append(this.$el)
    },100);
  },
  beforeDestroy() {
    vuecc.bus.$off('activeTimeLine')
  },
  methods:{
    activeTimeLine(index){

      if(index){
        let ref = this.$refs.timelineItem.find(item=>{
          return index == item.attributes["transform-index"].value
        })
        this.$refs.timelineContent.scrollTop = ref.offsetTop-70;
      }else{
        this.$refs.timelineContent.scrollTop = 0;
      }

    },
    changeTime(){
      this.$emit('changeTime',this.datetime);
    },
    item_active(item) {
      let index = item.index;
      //有了index 就可以从tacklayer里面找到eles中对应的ele 然后执行active()
      // let map = this.$store.state.track.map;
      // let trackLayer = map.trackLayer;
      let ele = this.trackLayer.findEleByIndex(index);
      if (ele) {
        this.trackLayer.active(ele);
      }
    },
    initDealTimeLine({transformData,trackLayer,trackTimeLineStatus,track_res}){
      this.trackLayer = trackLayer;
      this.track_res = track_res;
      let timeLine = new TimeLine(  transformData ).getData();
      this.dealTimeLine(timeLine,trackTimeLineStatus);

    },
    dealTimeLine(timeLineData,trackTimeLineStatus) {
      //按照时间将eventData插入进来

      let eventData = trackTimeLineStatus.map(item =>{
        return {
          id:item.point_info.id,
          create_time:item.time,
          type:item.status_name,
          lng:item.point_info.lng,
          lat:item.point_info.lat,
          code:item.other_info.task_num,
          case_id:item.other_info.id,
          location:item.point_info.address,
          need_deteail:item.is_need_detail,
        }
      })
      for (let i = 0; i < timeLineData.length; i++) {
        let thisTime = timeLineData[i].timestamp;
        let nextTime = timeLineData[i + 1]
            ? timeLineData[i + 1].timestamp
            : 9999999999; //没有是最后一个，视为无限
        timeLineData[i].eventData = [];
        eventData.forEach(item => {
          if (i === 0 && item.create_time < thisTime) {
            timeLineData[i].eventData.push(item);
          }
          if (item.create_time >= thisTime && item.create_time < nextTime) {
            timeLineData[i].eventData.push(item);
          }
        });

        // if(timeLineData[i].data_type=='move'){
        //   timeLineData[i].eventData = window.eventData
        //   break
        // }
      }

      this.timeLineData = timeLineData;
    }

  }
}
