import {message} from "ant-design-vue";

let context = {}
export default class PathAnimCtrl {
  constructor(props) {
    this.vue = props.vue;

    this.isPlay = false;
    this.progress_btn_click = false;
    this.first_click_progress = true;
    this.progress_left = 0;
    this.isPasue = false;
    this.percents = 0;
    this.isFinished = false;
    this.trackLayer = null;
    this.pathAnimLayerLayer = null;
  }

  setTrackLayer(trackLayer){
    this.trackLayer = trackLayer;
  }
  setPathAnimLayerLayer(pathAnimLayerLayer){
    this.pathAnimLayerLayer = pathAnimLayerLayer;
  }
  progress_bg(left, maxW) {
    if (!this.checkData()) {
      return false;
    }
    if (!this.checkPlay()) {
      return false;
    }
    this.progress_left = left;
    let progress = this.progress_left / maxW;
    this.vue.setStartByProgress(progress);
    this.changeProgress();
    this.vue.table_scroll_drag(this.percents);
  }

  progress_btn(e) {
    if (!this.isPlay) {
      // return false;
    }
    this.progress_btn_click = true;

    let that = this;
    let tag = false,
      ox = 0,
      left = 0,
      bgleft = 0;
    ox = e.pageX - that.progress_left;
    if (this.first_click_progress) {
      document.addEventListener('mouseup',function() {
        that.progress_btn_click = false;
      });
      document.addEventListener('mousemove',function(e) {
        if (that.progress_btn_click) {
          if (!that.checkData()) {
            return false;
          }
          if (!that.checkPlay()) {
            return false;
          }
          that.progress_left = e.pageX - ox;
          that.changeProgress();
          that.vue.table_scroll_drag(that.percents);
          // cc.event.triggerListener("table_scroll_drag", that.percents);
        }
      });
    }
    this.first_click_progress = true;

    return false;
  }
  changeProgress() {
    let that = this;
    if (!that.isPasue && that.isPlay) {
      that.trackplay();
    }

    // 判断是否为结束后的进度改变,是则暂停动画,并取消结束状态
    if (this.isFinished) {
      this.pathAnimLayerLayer.tween.pause();
      this.isFinished = false;
    }
    let maxW =
      this.vue.$refs.video_progress.clientWidth ||
      this.vue.$refs.video_progress.offsetWidth;
    if (that.progress_left <= 0 || window.isNaN(that.progress_left)) {
      that.progress_left = 0;
    } else if (that.progress_left > maxW) {
      that.progress_left = maxW;
    }

    let perc = that.progress_left / maxW;
    this.percents = perc;

    // 先改变回放视频的判断值，以免触发重播
    // 同时改变轨迹动画进度

    this.pathAnimLayerLayer.tween &&
      this.pathAnimLayerLayer.tween.progress(this.percents);
    this.pathAnimLayerLayer.setStartByProgress(this.percents);

    let cur_time = this.isPlay
      ? this.pathAnimLayerLayer.cur_time
      : this.getTimeByProgress(this.percents);
    if (this.isPlay) this.pathAnimLayerLayer.cur_index = -1;
    this.vue.track_progress_change({
      progress_left: that.progress_left,
      time: cur_time
    });
    // this.$dom.closest(".map_bottom").css("z-index", 201);
    // cc.event.triggerListener("video_progress_change", { is_pause: true });
  }
  getTimeByProgress(progress) {
    let td = this.transform_data;
    let start_time = Math.floor(td[0].start_time);
    let end_time = Math.floor(td[td.length - 1].end_time);
    return start_time + (end_time - start_time) * progress;
  }

  track_anim_index_change(params) {
    console.log(params.cur_index, "到点了11");
    //如果是清运信息11
    //匹配清运点显示清运信息
    // this.set_trashTransport()
    this.vue.track_table_scroll({ cur_index: params.cur_index });
  }

  progress_re_render(transform_data,anim_data) {
    this.anim_data = anim_data||[];
    this.transform_data = transform_data;
    // 有个 cc.store.rePlayType
    //先重置进度条，防止切换无数据之后进度条渲染不正确
    let coordinates = [];
    for (let c = 0; c < transform_data.length; c++) {
      if (transform_data[c].move_arr && transform_data[c].move_arr.length > 1) {
        coordinates = [...coordinates, ...transform_data[c].move_arr];
      }
    }
    this.coordinates = [];
    for (let i in coordinates) {
      let origin = geoTransUtil.gcj02towgs84(
        coordinates[i].lng * 1,
        coordinates[i].lat * 1
      );
      this.coordinates.push(origin);
    }
    // this.all_distence  = ruler.lineDistance(this.coordinates);   //全程的距离
    if (anim_data.length > 0) {
      this.all_time_range =
        anim_data[anim_data.length - 1].time -
        anim_data[0].time; //全程的时间
    }
    let progressList = [];
    for (let i = 0; i < transform_data.length; i++) {
      let tsf_data = transform_data[i];
      // 移动点数据
      if (tsf_data.move_arr && tsf_data.move_arr.length > 1) {
        //全部路径
        let item = tsf_data;
        let move_arr = item.move_arr;
        let coords = [];
        for (let x in item.move_arr) {
          let originX = geoTransUtil.gcj02towgs84(
            item.move_arr[x].lng * 1,
            item.move_arr[x].lat * 1
          );
          coords.push(originX);
        }

        // item.distance = ruler.lineDistance(coords);
        let time_range =
          move_arr[move_arr.length - 1].start_time - move_arr[0].start_time;
        let percent = (time_range / this.all_time_range) * 100;
        switch (item.pot_type) {
          case 6:
            progressList.push({
              percent: percent,
              color: "progress_bar_red",
              lineColor: "#ff4739"
            });
            break;
          case 7:
            progressList.push({
              percent: percent,
              color: "progress_bar_green",
              lineColor: "rgb(1,203,221)"
            });
            break;
          case 11:
            progressList.push({
              percent: percent,
              color: "progress_bar_purple",
              lineColor: "#5488ff"
            });
            break;
          case 12:
            progressList.push({
              percent: percent,
              color: "progress_bar_red",
              lineColor: "#ff4739"
            });
            break;
          case 13:
            progressList.push({
              percent: percent,
              color: "progress_bar_green",
              lineColor: "rgb(1,203,221)"
            });
            break;
        }
      } else if (
        tsf_data.pot_type == 3 ||
        (tsf_data.time_diff > 0 && tsf_data.pot_type == 1)
      ) {
        // 停留点数据
        let percent = (tsf_data.time_diff / this.all_time_range) * 100;
        progressList.push({
          percent: percent,
          color: "progress_bar_green",
          lineColor: "rgb(1,203,221)"
        });
      }
    }
    //修正长度，比如type6的线
    let htmlspan = "";
    let lostPercent = 0;
    for (let j = 0; j < progressList.length; j++) {
      if (j == progressList.length - 1) {
        htmlspan +=
          '<span class="' +
          progressList[j].color +
          '" style="width: ' +
          (100 - lostPercent) +
          "%;background:" +
          progressList[j].lineColor +
          '"></span>';
      } else {
        lostPercent += progressList[j].percent;
        htmlspan +=
          '<span class="' +
          progressList[j].color +
          '" style="width: ' +
          progressList[j].percent +
          "%;background:" +
          progressList[j].lineColor +
          '"></span>';
      }
    }
    if (htmlspan.length > 0) {
      return htmlspan;
    } else {
      return ``;
    }
  }
  checkPlay() {
    if (!this.isPlay) {
      // Tip('开始播放后，才能切换进度','error');
      // return false;
    }
    return true;
  }
  checkData() {
    if (this.anim_data.length < 2) {
      message.error("无轨迹路线！");
      return false;
    }
    return true;
  }

  trackplay() {
    if (!this.checkData()) {
      return false;
    }
    if (this.isPlay) {
      if (this.isPasue) {
        this.isPasue = false;

        if (this.isFinished) {
          this.vue.track_anim_restart();
          this.trackRestart();
          this.progress_left = 0;
          this.isFinished = false;
        } else {
          this.vue.track_anim_recover();
        }
        this.vue.status_play();
      } else {
        this.isPasue = true;
        // context.$store.commit("set_animIsPasue", this.isPasue);
        // context.$store.commit("set_animIsPlay", false);
        this.vue.track_anim_pasue();
        this.vue.status_pasue();
      }
      return false;
    }

    this.isPlay = true;
    // context.$store.commit("set_animIsPlay", this.isPlay);
    this.vue.status_play();
    this.vue.on_track_play();
  }
  trackRestart() {
    this.track_anim_index_change({ cur_index: 0, cur_len: 0 });
  }

  trackstop() {
    if (this.anim_data.length < 2) {
      return false;
    }
    if (!this.isPlay) {
      return false;
    }
    this.progress_left = 0;

    // if (localStorage.use_2d_map != "1") {
    //   buildingbox.setTransport(false);
    // }

    message.success("轨迹回放已结束。");
    this.isPlay = false;
    this.isPasue = false;

    this.vue.on_track_stop();
    this.vue.status_pasue();
    this.pathAnimLayerLayer.setPassedLine([]);
    vuecc.bus.$emit("activeTimeLine",0)
    //显示作业轨迹按钮

    // this.resetLine();
  }
  anim_finish() {
    this.isFinished = true;
    this.isPasue = true;
    // this.vue.$emit("track_stop");
    this.vue.status_pasue();
  }

  resetLine() {
    let lines = this.trackLayer.eles.filter(item => item.type == "line");
    for (let line of lines) {
      line.ele.resetLine();
    }
  }
}

//下面是原来的事件触发代码
// this.on("click", ".sub", this.subClick);
// this.on("click", ".gjPlay", this.trackplay);
// this.on("click", ".gjStop", this.trackstop);
// //返回按钮退出轨迹回放
// let that = this;
// $(".animation_nav_return").click(function() {
//   that.trackstop();
// });
// this.on("mouseleave", this.map_videoes_leave);
// this.on("click", ".progress_bg", this.progress_bg);
// this.on("mousedown", ".progress_btn", this.progress_btn);
// this.addListener("track_change", this.track_change);
// this.addListener("track_anim_index_change", this.track_anim_index_change);
// cc.event.addListener("trashTransport_reset", this.trashTransport_reset);
// this.addListener(
//   "track_anim_progress_change",
//   this.track_anim_progress_change
// );
// this.addListener("trackplay", this.trackplay);
// this.addListener("trackrestart", this.trackRestart);
// this.addListener("anim_finish", this.anim_finish);
// this.addListener("resetFinish", this.resetFinish);
// this.addListener("hideVideoBtn", this.hideVideoBtn);
// cc.event.addListener("progress_re_render", this.progress_re_render);
