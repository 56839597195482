

import shangbandaka from './imgs/event/icon_1.0_0.1_sbdk.png'
import dakaxiaban from './imgs/event/icon_1.0_0.1_xbdk.png'
import hecha from './imgs/event/icon_1.0_0.1_hc.png'
import zifazichu from './imgs/event/icon_1.0_0.1_zfzc.png'
import wentishangbao from './imgs/event/icon_1.0_0.1_wtsb.png'
import heshi from './imgs/event/icon_1.0_0.1_zfzc.png'

import timeIcon from './imgs/event/bg_3.0_0.7_sj.png'
import moment from 'moment'
import {getLayerServer} from 'xb-lib-view/dist/utils';
import { getMapClass } from "../adapter/adapter-three";

export class EventMarker {
    constructor(props) {
        this.markerDatas= props.data
        this.mapObj = props.mapObj
        this.markerList =[]
        this.init()
    }
    init(){
        let markerDatas = this.markerDatas.map(item=>{
            return {
                id:item.point_info.id,
                create_time:item.time,
                type:item.status_name,
                lng:item.point_info.lng,
                lat:item.point_info.lat,
                code:item.other_info.task_num,
                location:item.point_info.address,
                need_deteail:item.is_need_detail&&false,
            }
        })
        markerDatas = markerDatas.filter(item=>{
            if(item.lng && item.lat){
                item.lng = parseFloat(item.lng)
                item.lat = parseFloat(item.lat)
                return true
            }else{
                return false
            }

        })
        markerDatas.forEach(item=>{
            let newlnglat =[parseFloat(item.lng),parseFloat(item.lat)]
            item.lng=newlnglat[0]
            item.lat=newlnglat[1]
            this.createMarker(item)
        })
    }
    async showToolTip(id){
        let data =  this.markerDatas.find(item=>item.point_info.id == id)
	      this.infoWindow?.close?.()
	      this.infoWindow?.setMap?.(null)
        this.infoWindow=null
        let el = document.createElement("div");
        el.className = "map_track_marker_pop";
        // el.style["border-radius"] = "5px";
        el.style["padding"] = "12px 20px";
        el.style["color"] = "#fff";
        el.style["font-size"] = "16px";
        let info = [];
        info.push("<div style='display: flex;align-items: center;justify-content: flex-start;color: rgba(255,255,255,0.7);margin: 8px 0'><img src='"+timeIcon+"' style='margin: -6px -6px -6px -12px ;'/>" +  moment(Math.floor(data.time)*1000).format('HH:mm:ss')+ "<span style='display: inline-block;margin-left: 3px;color: #fff;'>"+data.status_name+"</span>" + "</div>");
        if(data.other_info.task_num){
            info.push(`
            <div style='color: rgba(255,255,255,0.7);margin: 8px 0'>
                <span>
                    案件编号 :
                    <span style='color: #fff'>${data.other_info.task_num}</span>
                </span>
                ${data.is_need_detail&&false?`<span><a  onclick="vuecc.bus.$emit('case-detail-pop',{id:${data.other_info.id}});" target="_blank"> 查看详情</a></span>`:``}
            </div>
`);
        }
        info.push("<div style='color: rgba(255,255,255,0.7);margin: 8px 0'>位置 : <span style='color: #fff'>" +  data.point_info.address?data.point_info.address: '未获取到定位信息'  + " </span></div>");

        el.innerHTML = info.join("");
	      let MapClass = getMapClass();
        this.infoWindow = new MapClass.InfoWindow({
            isCustom: true, //使用自定义窗体
            content: el,
            anchor:`bottom-center`,
	          position: [Number(data.point_info.lng), Number(data.point_info.lat)]
	        
        });
        let {conf,layerServer} = await getLayerServer();
        this.infoWindow.open?.(this.mapObj,layerServer.toMapLayer(data.point_info.lng,data.point_info.lat))
		    this.infoWindow.setMap?.(this.mapObj);
		    this.mapObj.setZoom(17);
		    this.mapObj.setCenter([Number(data.point_info.lng), Number(data.point_info.lat)]);
	
    }

    async createMarker(data){
        window.event_showToolTip = this.showToolTip.bind(this)

        let el = document.createElement("div");

        let icon=``
        switch (data.type) {
            case"上班":
                icon=shangbandaka
                break
            case"下班":
                icon=dakaxiaban
                break
            case"核查":
                icon=hecha
                break
            case"自发自处":
                icon=zifazichu
                break
            case"问题上报":
                icon=wentishangbao
                break
            case"核实":
                icon=heshi
                break
        }

        el.className = "";
        el.innerHTML =
            `<div class="event_marker">
                <div class="event_name" style="    white-space: nowrap;
    background: #373535b5;
    padding: 3px 10px;
    border-radius: 5px;">${data.type}</div>
                <div class="content event_marker_tip_${data.id}" data-id="${data.id}">
                
                <img src="${icon}" onclick="event_showToolTip(${data.id})" class="event-icon"/>
                <div></div>
                
                </div>
            </div>`;
        let {conf,layerServer} = await getLayerServer();

        let marker = new AMap.Marker({
            content: el,
            anchor:"top-left",
            offset:new AMap.Pixel(-17,-50),
            zIndex:999,
            position: layerServer.toMapLayer(
                parseFloat(data.lng),
                parseFloat(data.lat)
            )
        });

        marker.setMap(this.mapObj)
        this.markerList.push(marker)
    }
    destroy(){
        this.markerList.forEach(item=>{
            item.setMap(null)
        })
        this.markerList=[]
    }
}