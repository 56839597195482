import {getLayerServer} from 'xb-lib-view/dist/utils';
import { getMapClass } from "../adapter/adapter-three";

export default class Line {
  static Enum = {
    track_normal: 1, //正常
    track_error: 2, //异常
    track_rest: 3, //休息
    track_over_speed: 4, //超速
    track_work: 5, //作业
    track_violation: 7, //违规
    track_work_over_speed: 6 //作业超速
  };
  linezIndex = 1;
  constructor(props) {
    this.mapObj = props.mapObj;
    this.trackLayer = props.trackLayer;
    this.transformData = props.transformData;
    this.group = props.group;
    this.path = props.path;
    this.index = props.index;
    this.type = props.type;
    this.zIndex = ++this.linezIndex;
    this.line_options = null;
    this.infoWindow = null;
    this.lineArr = null;
    this.activing = false; //由于点击线的事件在mapbox中无法阻止冒泡，只能手动写个锁，触发active事件之后100毫秒不能解除active
  }

  resetLine() {
    if (this.activing) {
      return;
    }
	  this.line.setOptions({
		  ...this.line_options,
		  fillColor: this.line_options.strokeColor,
	  });
		if (this.infoWindow) {
      this.infoWindow.close?.();
			this.infoWindow.setMap?.(null);
    }
  }
  createElement() {
    let el = document.createElement("div");
    el.className = "map_track_marker_pop";
    // el.style["max-width"] = "150px";
    // el.style["margin-bottom"] = "30px";
    // el.style["background"] = "#fff";
    // el.style["border-radius"] = "5px";
    // el.style["padding"] = "5px";
    // el.style["border"] = "1px solid #e1e1e1";

    el.style["padding"] = "12px 20px";
    el.style["color"] = "rgba(255,255,255,0.7)";
    el.style["font-size"] = "16px";

    return el;
  }
  async active(e) {
    this.activing = true;
    e && e.originalEvent && e.originalEvent.stopPropagation();
    this.trackLayer.resetElement();
    let lnglat;
    if (e && window.R_AMap) {
      lnglat = new AMap.LngLat(e.lngLat.lng, e.lngLat.lat);
    } else if (e) {
      lnglat = e.lnglat;
    }
    if (!lnglat) {
      let arr = this.lineArr[Math.floor(this.lineArr.length / 2)];
      if (window.R_AMap) {
        lnglat = new AMap.LngLat(arr[0], arr[1]);
      } else {
        let {conf,layerServer} = await getLayerServer();
        lnglat = layerServer.toMapLayer(arr[0],arr[1]);
      }
    }
    let index = this.index;
    let transformData = this.transformData;
    let el = this.createElement();
    el.innerHTML = transformData[index].move_data.map_line_move_str;
	  this.infoWindow?.setMap?.(null);
	  let MapClass = getMapClass();
    this.infoWindow = new MapClass.InfoWindow({
      isCustom: true, //使用自定义窗体
      content: el,
	    position: lnglat,
    });
	
	  let [lng, lat] = lnglat;
    this.mapObj.clearInfoWindow?.(this.infoWindow);
    // this.mapObj.panTo(lnglat);


    setTimeout(() => {
      this.infoWindow.open?.(this.mapObj, lnglat);
	    this.infoWindow.setMap(this.mapObj);
	    this.infoWindow.setPosition([lng, lat]);
    },1);
		
		
    //line 的active动作
	  let opt = { ...this.line_options, fillColor: "#00ff00" };
    opt.strokeColor = "#00ff00";
    this.line.setOptions(opt);
    setTimeout(() => {
      this.activing = false;
    }, 100);
  }
  clear() {
    if (this.line) {
      //这条线总路线
      this.line.setMap(null);
    }
  }
  render() {}

  async createLine(params) {
    let dataArr = this.path;
    let type = this.type;
    //添加线
    let split_line_point_arr = splitPointArrUnableToReach(dataArr, type);
    for (let k in split_line_point_arr) {
      let point_arr_obj = split_line_point_arr[k];
      let lineArr = []; //创建线覆盖物节点坐标数组
      for (let i in point_arr_obj["list"]) {
        let temp_point = point_arr_obj["list"][i];
        let origin = [temp_point.lng * 1, temp_point.lat * 1];
        lineArr.push(origin);
      }

      let opt = {
        strokeWeight: 10,
        strokeColor: "rgb(1,203,221)",
        extData: {},
      };
      if (type == Line.Enum.track_error) {
        opt.strokeStyle = "dashed";
        opt.strokeColor = "#FC5515";
        opt.strokeDasharray = [10, 10];
        opt.extData.default_color = "#FC5515";
      } else if (type == Line.Enum.track_rest) {
        //休息 或 下班时间点
        opt.strokeStyle = "dashed";
        opt.strokeColor = "#a1a3a0";
        opt.strokeDasharray = [10, 10];
      } else if (type == Line.Enum.track_work) {
        opt.strokeColor = "#5488ff";
      } else if (type == Line.Enum.track_over_speed) {
        opt.strokeColor = "#ff4739";
      } else if (type == Line.Enum.track_work_over_speed) {
        opt.strokeColor = "#ff4739";
      } else if (type == Line.Enum.track_violation) {
        opt.strokeColor = "#ff4739";
      }
      let {conf,layerServer} = await getLayerServer();

      opt.path = lineArr.map(item => layerServer.toMapLayer(item[0],item[1]));
      this.lineArr = lineArr;
      this.line_options = opt;
      opt.showDir=true
	    let MapClass = getMapClass();
      this.line = new MapClass.Polyline(opt);
      this.line.setMap(this.mapObj);
      this.line.on("click", this.active.bind(this));
      //lineArr
    }
  }
}
//分割前后点，经纬度偏差直线距离，按照该点速度，无法达到，则分割为2个线段
function splitPointArrUnableToReach(line_point_arr, type) {
  let split_line_point_arr = [];

  let last_point = null;
  let now_point = null;

  let new_line_point_arr = [];

  for (let k in line_point_arr) {
    if (last_point === null) {
      last_point = line_point_arr[k];
    } else {
      now_point = line_point_arr[k];

      if (isNeetSplit(now_point, last_point, type)) {
        let tmp_new_arr = $.extend(true, [], new_line_point_arr);
        split_line_point_arr.push({
          is_hide: 0,
          list: tmp_new_arr
        });

        split_line_point_arr.push({
          is_hide: 1,
          list: [last_point, line_point_arr[k]]
        });

        new_line_point_arr = $.extend(true, [], []);
      }

      last_point = line_point_arr[k];
    }

    new_line_point_arr.push(line_point_arr[k]);
  }

  if (new_line_point_arr.length > 0) {
    split_line_point_arr.push({
      is_hide: 0,
      list: new_line_point_arr
    });
  }

  return split_line_point_arr;
}

function isNeetSplit() {
  return false;
}
function getDistance($lat1, $lng1, $lat2, $lng2) {
  let $len_type = 1;
  let $decimal = 2;
  let $EARTH_RADIUS = 6378.137;
  let $PI = 3.1415926;
  let $radLat1 = ($lat1 * $PI) / 180.0;
  let $radLat2 = ($lat2 * $PI) / 180.0;
  let $a = $radLat1 - $radLat2;
  let $b = ($lng1 * $PI) / 180.0 - ($lng2 * $PI) / 180.0;
  let $s =
    2 *
    Math.asin(
      Math.sqrt(
        Math.pow(Math.sin($a / 2), 2) +
          Math.cos($radLat1) *
            Math.cos($radLat2) *
            Math.pow(Math.sin($b / 2), 2)
      )
    );
  $s = $s * $EARTH_RADIUS;
  $s = Math.round($s * 1000);
  if ($len_type > 1) {
    $s /= 1000;
  }
  return Math.round($s, $decimal);
}
