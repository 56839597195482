import Marker from "./Marker";
import Line from "./Line";
import * as turf from "@turf/turf";

import { message } from "ant-design-vue";
import './style/track.less';
import { getMapClass } from "../adapter/adapter-three";

export default class TrackLayer {
  constructor(transformData, mapObj) {
    this.mapObj = mapObj;
    this.transformData = transformData;
    this.eles = [];
    this.line_paths = [];
    this.first_point = [];
	  this.originView = {};
  }
  clearTrack() {
    this.eles.forEach(item => {
      item.ele.clear();
    });
  }
  async renderTrack(params) {
    let eles = this.toMapData();
    for (let i in eles) {
      let ele = eles[i].ele;
      let type = eles[i].type;
      if (type == "line") {
        await ele.createLine(params);
        for (let j in ele.path) {
          let point = [
            ele.path[j].lng * 1,
            ele.path[j].lat * 1
          ];
          this.line_paths.push(point);
        }
      } else if (type == "marker") {
        await ele.createMarker();
        if (ele.data.lng && ele.data.lat && this.first_point.length === 0) {
          this.first_point = [ele.data.lng, ele.data.lat];
        }
      }
    }

    setTimeout(function() {
      for (let i in eles) {
        let ele = eles[i].ele;
        let type = eles[i].type;

        if (type == "line") {
          ele.render();
        } else if (type == "marker") {
          ele.render();
        }
      }
    }, 100);

    this.eles = eles;
  }
  findEleByIndex(index) {
    let ele = this.eles.filter(item => item.ele.index == index);
    return ele[0];
  }
  active(ele) {
    if (ele.type == "marker") {
      ele.ele.active();
    } else if (ele.type == "line") {
      ele.ele.active();
    }
  }
  resetElement() {
    this.eles.forEach(item => {
      if (item.type == "line") {
        item.ele.resetLine();
      }
      if (item.type == "marker") {
        item.ele.resetMarker();
      }
    });
  }
  fitBounds(no_tip) {
    console.log('llllllllllllllll',this.line_paths);
    if (this.line_paths.length > 1) {
      let line = turf.lineString(this.line_paths);
      let bbox = turf.bbox(line);
      if (AMap.name == "sdk") {
        let bounds = new AMap.Bounds([bbox[0], bbox[1]], [bbox[2], bbox[3]]);
        this.mapObj.setBounds(bounds, {
          padding: { top: 10, bottom: 20, left: 15, right: 5 }
        });
      } else {
        let lines = [];
	      let MapClass = getMapClass();
        this.mapObj.getAllOverlays().forEach(item => {
          if (item.CLASS_NAME === "Overlay.Polyline") {
            lines.push(item.getPath());
          }else if (item instanceof MapClass.Polyline) {
		        lines.push(item.getPath());
	        }
        });
	
        this.mapObj.setFitView(new MapClass.Polyline({path: lines.flat(2)}), true, [200, 200, 600, 600], 18);
        // this.mapObj.setBounds(
        //   new AMap.Bounds(geoTransUtil.wgs84togcj02(bbox[0], bbox[1]),geoTransUtil.wgs84togcj02(bbox[2], bbox[3]))
        // );
      }
    } else {
      if(!no_tip){
        message.info("当前没有轨迹线路");
      }
    }
  }
  toMapData() {
    //绘制点线的数据map
    //        show_fence(data.fence);
    //1移动，2停留，3虚假，4轨迹为空
    let eles = [];
    let data_data = this.transformData;
    for (let i = 0; i < data_data.length; ++i) {
      data_data[i]["is_end"] = false;
      let marker = null;
      let line = null;
      //标注当前位置
      switch (data_data[i].pot_type) {
        case 1: {
          marker = new Marker({
            data: data_data[i],
            type: Marker.Enum.track_start,
            index: i,
            mapObj: this.mapObj,
            trackLayer:this,
          });
          break;
        }
        case 2: {
          marker = new Marker({
            data: data_data[i],
            type: Marker.Enum.track_end,
            index: i,
            mapObj: this.mapObj,
            trackLayer:this,
          });
          break;
        }
        case 3: {
          marker = new Marker({
            data: data_data[i],
            type: Marker.Enum.track_stop,
            index: i,
            mapObj: this.mapObj,
            trackLayer:this,
          });
          break;
        }
        case 4: {
          break;
        }
        case 5: {
          break;
        }
        case 6: {
          if (data_data[i].move_arr.length > 1) {
            let line_type = Line.Enum.track_error;
            if (data_data[i].line_color_type == 3) {
              line_type = Line.Enum.track_rest;
            }
            line = new Line({
              path: data_data[i].move_arr,
              type: line_type,
              index: i,
              mapObj: this.mapObj,
              transformData:this.transformData,
            trackLayer:this,
            });
          }

          break;
        }
        case 7: {
          line = new Line({
            path: data_data[i].move_arr,
            type: Line.Enum.track_normal,
            index: i,
            mapObj: this.mapObj,
            transformData:this.transformData,
            trackLayer:this,
          });
          break;
        }
        case 8: {
          marker = new Marker({
            data: data_data[i],
            type: Marker.Enum.track_point,
            mapObj: this.mapObj,
            trackLayer:this,
            index: i
          });
          break;
        }
        case 11:
          line = new Line({
            path: data_data[i].move_arr,
            type: Line.Enum.track_work,
            index: i,
            group: data_data[i].move_data_group,
            mapObj: this.mapObj,
            transformData:this.transformData,
            trackLayer:this,
          });
          break;
        case 12:
          line = new Line({
            path: data_data[i].move_arr,
            type: Line.Enum.track_violation,
            index: i,
            group: data_data[i].move_data_group,
            mapObj: this.mapObj,
            transformData:this.transformData,
            trackLayer:this,
          });
          break;
        case 13:
          line = new Line({
            path: data_data[i].move_arr,
            type: Line.Enum.track_normal,
            index: i,
            mapObj: this.mapObj,
            transformData:this.transformData,
            trackLayer:this,
          });
          break;
      }
      if (marker) {
        eles.push({
          ele: marker,
          type: "marker"
        });
      }
      if (line) {
        eles.push({
          ele: line,
          type: "line"
        });
      }
    }
    if (data_data.length > 0) {
      let data1 = {};
      data1 = data_data[data_data.length - 1];
      if (data1.pot_type == 6) {
        data1 = data_data[data_data.length - 2];
      }
      if (data1 && data1.lng > 0 && data1.lat > 0) {
        data1["is_end"] = true;
        let marker = new Marker({
          data: data1,
          type: Marker.Enum.track_his_end_point,
          mapObj: this.mapObj,
            trackLayer:this,
        });
        if (marker) {
          eles.push({
            ele: marker,
            type: "marker"
          });
        }
      }
    }
    return eles;
  }
}

//1起点，2终点，3停点，4虚点，5.断点，6异常点，7,移动点,8业务点   11作业点，12违规点，13行驶点
// track_normal:1,//正常
//     track_error:2,//异常
//     track_rest:3,//休息
//     track_over_speed:4,//超速
//     track_work:5,//作业
//     track_violation:7,//违规
//     track_work_over_speed:6//作业超速
