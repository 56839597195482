export default function switchTimeLength(timeLength, m, not_need_sec) {
  if (timeLength > -1) {
    var day = Math.floor(timeLength / 86400);
    var hour = Math.floor(timeLength / 3600) % 24;
    var minute = Math.floor(timeLength / 60) % 60;
    var sec = timeLength % 60;
    if (m == 1) {
      if (day == 0 && hour == 0 && minute == 0) {
        return sec + "秒";
      } else if (day == 0 && hour == 0) {
        return minute + "分钟";
      } else if (day == 0) {
        return hour + "小时";
      } else return day + "天";
    } else {
      var return_time_str = "";

      if (day > 0) {
        return_time_str += day + "天";
      }

      if (hour > 0) {
        return_time_str += hour + "小时";
      }

      if (minute > 0) {
        return_time_str += minute + "分钟";
      }

      if (sec > 0 && !not_need_sec) {
        return_time_str += sec + "秒";
      }

      if (return_time_str) {
        return return_time_str;
      } else {
        return "1分钟";
      }
    }
    if (m == 2) {
      if (day != 0) {
        return day + "天";
      } else if (minute != 0) {
        return minute + "分钟";
      } else if (hour != 0) {
        return hour + "小时";
      }
    } else {
    }
  } else return "";
}
