import * as turf from "@turf/turf";
import cheapRuler from "cheap-ruler";
const ruler = new cheapRuler(37.61, "meters");

import { TweenLite, Linear } from "gsap";
import moveCarIcon from '../imgs/icon_loc.webm'
import {getLayerServer} from 'xb-lib-view/dist/utils';
import { getMapClass } from "../../adapter/adapter-three";
import $ from "jquery";

export default class Track3D {
  constructor(props) {
    this.vue = props.vue;
    this.mapObj = props.mapObj;
    this.track_data = props.track_data;
    this.boundsOption = {}; //map moveend 用到的判断属性
    this.pathAnimCtrl = null;
    this.initEvent();
    // this.passedLine  = new AMap.Polyline({
    //   map: props.mapObj,
    //   showDir: true,
    //   strokeColor: "#777777",
    //   strokeWeight: 10,
    //   zIndex:999,
    //   strokeOpacity:0.9,
    // }) //轨迹动画走过的路线，覆盖再原轨迹上
   }
  setPathAnimCtrl(pathAnimCtrl){
    this.pathAnimCtrl = pathAnimCtrl;
  }
  initParams() {
    this.view_type = (() => {
      if (window.R_AMap) {
        return window.localStorage.getItem("view_type") || 1;
      } else {
        return 2;
      }
    })();
    this.bbox = { zoom: 15, pitch: 0 };
    this.cur_index = 0;
    this.cur_progress = 0;
    this.coordinates = [];
    this.distance = 0;
    this.tween = null;
    this.mesh = null;
    this.marker = null;
    this.time_line = null;
    this.has_finished = false;
    this.timeScaleValue = false;
    this.f_bbox = null;
    this.animate_run_time = 200;
    this.prev_timeScale = 1;
    this.start_time = 0;
    this.end_time = 0;
    this.cur_time = 0;
  }
  initPlayParams() {
    if (!this.coordinates || this.coordinates.length < 2) {
      this.start_bearing = 0;
    } else {
      this.start_bearing = ruler.bearing(
        this.coordinates[0],
        this.coordinates[1]
      );
    }
    this.cur_index = 0;
    this.cur_progress = 0;
    this.tween = null;
    if (this.mesh) {
      this.mesh.visible = false;
    }
    if (this.marker) {
      this.marker.setMap(null);
    }
    // this.mesh = null;
    // this.marker = null;
  }
  initEvent() {
    this.mapObj.on(
      "moveend",
      function(e) {
        if (!this.boundsOption.track_back) {
          return;
        }
        if (this.boundsOption.fit_bounds) {
          this.bbox.zoom = this.mapObj.getZoom();
          if (this.view_type == 1) {
            this.boundsOption = { track_back: true };
            this.mapObj.setZoomAndCenter(
              17,
              this.coordinates[this.cur_index],
              this.start_bearing - this.getBearingSlant()
            );
          } else {
            this.boundsOption = { track_back: true };
            this.start_anima();
            if (window.localStorage.getItem("view_type") == 2) {
              this.changeView(2);
            }

            // this.animate();
          }
        } else {
          this.start_anima();
        }
      }.bind(this)
    );
  }
  start_anima() {
    this.boundsOption = {};
    this.animate();
  }
  getPreScale() {
    return 2;
  }
  getBearingSlant() {
    return 10;
  }
  getPadding() {
    return { top: 80, bottom: 120, left: 80, right: 80 };
  }

  setCoordinates(coordinates) {
    this.initParams();
    this.coordinates = [];
    for (let i in coordinates) {
      let origin = [coordinates[i].lng * 1, coordinates[i].lat * 1];


      this.coordinates.push(origin);
    }
    this.distance = ruler.lineDistance(this.coordinates);
    this.initPlayParams();
  }
  setStarttime(start_time) {
    let cur_progress = this.getProgressByTime(start_time);
    let cur_index = this.getIndexByProgress(cur_progress);
    this.setIndex(cur_index);
    // this.tween.progress(cur_progress);
    //111
    // cc.event.triggerListener("trackplay", $(".gjPlay")); 需要改为用vue的形式来写
    // this.start();
  }
  setStartByProgress(progress) {
    let cur_index = this.getIndexByProgress(progress);
    this.setIndex(cur_index);
    this.cur_progress = progress;
  }
  getProgressByTime(time) {
    time = parseInt(time);
    let start_time = this.track_data.anim_data[0].time;
    let end_time =
      this.track_data.anim_data[this.track_data.anim_data.length - 1].time;
    let diff_time = end_time - start_time;
    return (time - start_time) / diff_time;
  }
  getTimeByProgress(progress) {
    let start_time = this.track_data.anim_data[0].time;
    let end_time =
      this.track_data.anim_data[this.track_data.anim_data.length - 1].time;
    let diff_time = end_time - start_time;
    return start_time + diff_time * progress;
  }
  getIndexByProgress(progress) {
    let cur_time = this.getTimeByProgress(progress);
    let coords_time_arr = this.track_data.anim_data.map(item => item.time);
    let point_index = coords_time_arr.findIndex(time => time > cur_time) - 1;
    return point_index;
  }
  setIndex(index) {
    if (this.distance > 0) {
      // this.cur_progress = ruler.lineDistance(this.coordinates.slice(0,index+1))/this.distance;
      this.cur_index = index;
      this.start_bearing = ruler.bearing(
        this.coordinates[index],
        this.coordinates[index + 1]
      );
    }
  }

  async start(view_type) {
    if (window.R_AMap) {
      //高德地图默认只有2d显示模式
      this.view_type = view_type;
    } else {
      this.view_type = 2;
    }
    let {conf,layerServer} = await getLayerServer();
    this.layerServer = layerServer;
    this.initCar(
      function() {
        this.startPlay();
      }.bind(this)
    );
  }
  toMapLayer(lng,lat){
    return this.layerServer.toMapLayer(lng,lat);
  }
  getGaodeLngLat(lnglat){
    if(lnglat.lng){
      return this.layerServer?.toGaode(lnglat.lng,lnglat.lat);
    }
    return this.layerServer.toGaode(lnglat[0],lnglat[1]);
  }
  initCar(fn) {

    let el = document.createElement("div");
    el.className = "";
    el.innerHTML =`<div class="map_track_marker rotate_ele"><video autoplay muted loop style="width: 30px;" src="${moveCarIcon}" /></div>`;
	  let MapClass = getMapClass();
   
		this.marker = new MapClass.Marker({
      content: el,
      // offset:new MapClass.Pixel(-15, -80),
      position: [
        parseFloat(this.coordinates[this.cur_index][0]),
        parseFloat(this.coordinates[this.cur_index][1])
      ],
      zIndex:99999,
    });


      this.marker.setMap(this.mapObj);
      this.moveCar(this.coordinates[this.cur_index], this.start_bearing);
      fn();
      return

  }
  moveCar(lnglat, bearing) {
    // if (window.R_AMap) {
    //   this.mesh.rotation.y = Math.PI * 2 - (Math.PI / 180) * (bearing + 180);
    //   threebox.moveToCoordinate(this.mesh, lnglat, {
    //     preScale: this.getPreScale()
    //   });
    // }
    this.marker.setPosition(this.toMapLayer(lnglat[0], lnglat[1]));
    if(document.querySelector(".map_track_marker.rotate_ele")){
      let video = document.querySelector(".map_track_marker.rotate_ele video");
      if(video.paused){
        video.play()
      }
      if(bearing>0) {
        if(!video.classList.contains('scaleX')){
          video.classList.add("scaleX")
        }
      }
      else{
        if(video.classList.contains('scaleX')){
          video.classList.remove("scaleX")
        }
      }
    }
    // console.log(bearing)

    // if(this.mapObj.getRotation()){}
    //TODO 改成带方向的人物图片
    // this.marker.setAngle(bearing - this.mapObj.getRotation());
  }
  moveMaker(lnglat, bearing) {
    this.mesh.rotation.y = Math.PI * 2 - (Math.PI / 180) * bearing;
    if (window.R_AMap) {
      threebox.moveToCoordinate(this.mesh, lnglat, {
        preScale: this.getPreScale()
      });
    }
    this.marker.setPosition(new AMap.LngLat(lnglat[0], lnglat[1]));
    // this.marker.setAngle(bearing - this.mapObj.getRotation());
  }
  startPlay() {
    let coordinates = this.coordinates;
    let line = turf.lineString(coordinates);
    let bbox = turf.bbox(line);
    this.f_bbox = bbox;

    this.boundsOption = { track_back: true, fit_bounds: true };
    // this.mapObj.setBounds(
    //   new AMap.Bounds([bbox[0], bbox[1] + 0.001], [bbox[2], bbox[3] + 0.001])
    // );
    setTimeout(()=>{
    let center = this.mapObj.getCenter()
      center = this.getGaodeLngLat([center.lng+0.00000001,center.lat+0.00000001])
     this.mapObj.setCenter(center);
    },500)
  }

  timeScale(scale) {
    if (this.tween) {
      this.tween.timeScale(scale);
    } else {
      this.timeScaleValue = scale;
    }
  }
  timeScaleToSecond() {
    this.prev_timeScale = $(".map_video_speed")
      .find("li.page_active")
      .data("speed");
    if (this.view_type == 1) cc.store.map.mapObj.setZoom(18.6);
    this.timeScale(0.02);
  }
  timeScaleToPrev() {
    if (this.view_type == 1) cc.store.map.mapObj.setZoom(17);
    this.timeScale(this.prev_timeScale);
  }

  pause() {
    if (this.tween) {
      this.tween.pause();
    }
  }
  play() {
    this.tween.play();
  }
  stop() {
    if (this.tween) {
      this.tween.kill();
    }
    this.initPlayParams();

    this.vue.track_anim_progress_change({
      percent: 0,
      time: 0
    });


  }
  resume() {
    this.tween.resume();
  }
  changeView(view_type) {
    this.view_type = view_type;
    window.localStorage.setItem("view_type", view_type);
    $(`.map_btn_left_bot>div[data-view_type="${view_type}"]`)
      .addClass("cur")
      .siblings()
      .removeClass("cur");
    if (view_type == 1) {
      this.mapFly({
        zoom: 17,
        pitch: 0,
        center: this.coordinates[this.cur_index]
      });
      this.marker.remove();
      if (this.mesh) {
        this.mesh.visible = true;
      }
      // if (this.track_data.is_car) {
      //
      // }
    } else {
      // this.mapFly({zoom:this.bbox.zoom,pitch:this.bbox.pitch});
      let bbox = this.f_bbox;
      this.boundsOption = { fit_bounds: true };
      // this.mapObj.setBounds(
      //   new AMap.Bounds([bbox[0], bbox[1]], [bbox[2], bbox[3]])
      // );
      this.marker.addTo(this.map);
      if (this.mesh) {
        this.mesh.visible = false;
      }
    }
  }
  mapFly(options) {
    let pos = { zoom: this.mapObj.getZoom(), pitch: this.mapObj.getPitch() };
    TweenLite.to(pos, 2, {
      zoom: options.zoom,
      pitch: options.pitch,
      onUpdate: function() {
        this.mapObj.setZoom(pos.zoom);
        // this.mapObj.setPitch(pos.pitch);
      }.bind(this)
    });
  }
  setPassedLine(path){
    // if(path.length){
    //   this.passedLine.show();
    //   this.passedLine.setPath(path.map(item => this.toMapLayer(item[0],item[1])))
    // }else{
    //   this.passedLine.hide();
    // }
  }
  animate() {
    let coordinates = this.coordinates;
    let coords_time_arr = this.track_data.anim_data.map(item => item.time);
    let coords_speed_arr = this.track_data.anim_data.map(item => item.speed);
    let cur_point = coordinates[this.cur_index];
    let start_time = this.track_data.anim_data[0].time;
    let end_time =
      this.track_data.anim_data[this.track_data.anim_data.length - 1].time;
    this.start_time = start_time;
    this.end_time = end_time;
    let time_range = end_time - start_time;
    let pos = { len: 0, anim_move_data_index: 0, time: this.start_time };
    // 初始化时第一个点
    this.pathAnimCtrl.track_anim_index_change({ cur_index: 0 });
    // let animate_run_time = this.distance / 100;
    // 根据配置设置不同的初始动画速度
    let animate_run_time = time_range / 50; //50是默认的
    this.animate_run_time = animate_run_time;
    let prev_bearing = 0;
    this.tween = TweenLite.to(pos, animate_run_time, {
      len: this.distance,
      anim_move_data_index: this.track_data.anim_move_data.length - 1,
      time: this.end_time,
      onUpdate: function() {
        let params = this.tween;
        let newSpot =
          this.track_data.anim_move_data[parseInt(pos.anim_move_data_index)];
        let has_move_coords = this.track_data.anim_move_data.slice(
          0,
          parseInt(pos.anim_move_data_index)
        );
        this.setPassedLine(has_move_coords)

        let len = ruler.lineDistance(has_move_coords);
        let newSpot_ = ruler.along(coordinates, len);
        let point = ruler.pointOnLine(coordinates, newSpot_);
        let bearing = ruler.bearing(cur_point, newSpot);
        bearing = bearing || prev_bearing;
        // console.log(this.cur_index, point.index, len);
        let point_index =
          coords_time_arr.findIndex(time => time > pos.time) - 1;
        // if(this.track_data.anim_data.map(item=>item.timeStr)[point_index] === '5:20:55'){debugger}
        if (Number(coords_speed_arr[point_index + 1]) === 0) {
          point_index += 1;
        }
        // if (this.cur_index === 99){debugger}
        if (this.cur_index < point_index) {
          this.pathAnimCtrl.track_anim_index_change({
            cur_index: point_index,
            cur_len: len
          });

          this.cur_index = point_index;
        }
        let transform_index = this.track_data.anim_data[this.cur_index].transform_index
        vuecc.bus.$emit("activeTimeLine",transform_index)
        this.moveCar(newSpot, bearing);

        // cc.event.triggerListener("checkOutOffTimeSlot", { cur_time: pos.time });

        if (this.view_type == 1) {
          this.mapObj.setCenter(new AMap.LngLat(newSpot[0], newSpot[1]));
          this.mapObj.setRotation({
            bearing: bearing - this.getBearingSlant(),
            duration: 300
          });
        } else {
        }
        cur_point = newSpot;
        // cc.event.triggerListener('track_anim_progress_change', {percent: pos.len / this.distance, cur_index: this.cur_index});
        this.cur_time = pos.time;
        prev_bearing = bearing;

        this.vue.track_anim_progress_change({
          percent: params.progress(),
          time: pos.time
        });
      }.bind(this),
      onUpdateParams: ["{self}"],
      ease: Linear.easeNone,
      onComplete: function() {
        //触发最后一个点
        this.pathAnimCtrl.track_anim_index_change({
          cur_index: this.track_data.anim_data.length - 1,
          cur_len: this.distance
        });

        console.log("finish");
        this.has_finished = true;
        // cc.event.triggerListener("anim_finish");
        this.pathAnimCtrl.anim_finish();
      }.bind(this)
    });
    this.tween.progress(this.cur_progress);
    if (this.timeScaleValue) {
      this.timeScale(this.timeScaleValue);
    }
  }

  onTimeLineUpdate(params) {
    console.log(params.progress());
  }
}
