import { onMounted, onUnmounted, ref } from "@vue/composition-api";
import { waitUntilOk } from "@/utils/waitUntilOk";
import XPlayer from 'xb-video-player-sdk';

export function useVideoMonitor (previewApi) {
	let video_ref = ref(null);
	let video_list = ref([
	// 		{
	// 	name: "01",
	// 	value: 1,
	// 	checked: true
	// },
	
	]);
	let curDeviceInfo = {};
	let xplayer =null;
	const setVideoList = (deviceInfo,list) => {
		video_list.value = list;
		if(list.length){
			curDeviceInfo = deviceInfo;
			changePlayItem(0);
		}
	}
	const changePlayItem = (index) => {

		previewApi(curDeviceInfo,video_list.value[index]).then(async res =>{
			if(res.ok){
				await waitUntilOk(()=>{
					return video_ref.value
				});
				//
				let url = new URL(res.ws_url);
				xplayer =new XPlayer(video_ref.value,{
					media_server:{
						wsProtocol:url.protocol.split(':')[0],
						ip:url.hostname,
						rtspPort:554,
						wsPort:url.port
					},
				});
				xplayer.play(res.play_url)
			}
		})
		//
	}
	onUnmounted(()=>{
		if(xplayer){
			xplayer.stop();
		}
	});
	onMounted(()=>{
		console.log('video_ref',video_ref.value);
	})
	return {
		video_ref,
		video_list,
		setVideoList,
		changePlayItem,
	}
}