import switchTimeLength from "../tool/switchTimeLength.js";
import formatSeconds from "../tool/formatSeconds.js";
import moment from 'moment'
export default class TimeLine {
  constructor(props) {
    this.data = props; //transformData
  }
  getData() {
    let data_data = this.data;
    let datas = [];
    let last_pot_type = null;
    let data1 = null;
    let is_end = 0;
    data_data.forEach((item, index) => {
      //1起点，2终点，3停点，4虚点，5.断点，6异常点，7,移动点,8业务点
      if (index > 0) {
        last_pot_type = data_data[index - 1].pot_type;
      }
      if (data_data[data_data.length - 1].pot_type == 6) {
        data1 = data_data[data_data.length - 2];
      }
      if (index == data_data.length - 1) {
        is_end = 1;
      }
      data_data[index]["is_end"] = false;
      let dataBlock = {};
      switch (item.pot_type) {
        case 1: {
          item.type = 1;
          dataBlock = data_ok(item, index);
          break;
        }
        case 2: {
          item.type = 2;
          dataBlock = data_ok(item, index);
          break;
        }
        case 3: {
          item.type = 3;
          dataBlock = data_ok(item, index);
          break;
        }
        case 4: {
          dataBlock = data_error(item, index);
          break;
        }
        case 6: {
          let has_line = false;
          if (item.move_arr.length > 1) {
            has_line = true;
          }
          let time_pos =
            last_pot_type == 4 ? (is_end != 1 ? "no" : 2) : is_end != 1 ? 1 : 3;

          dataBlock = data_error(item, index, time_pos, has_line); //需要判断有没有线
          break;
        }
        case 7: {
          item.type = 1;
          dataBlock = data_move(item, index);
          break;
        }
        case 8: {
          item.type = 3;
          dataBlock = data_ok(item, index);
          break;
        }
        case 9: {
          item.type = 4;
          dataBlock = data_ok(item, index);
          break;
        }
        case 10: {
          item.type = 5;
          dataBlock = data_ok(item, index);
          break;
        }
        case 11: {
          item.type = 11;
          dataBlock = data_work(item, index);
          break;
        }
        case 12: {
          item.type = 12;
          dataBlock = data_warning(item, index);
          break;
        }
        case 13: {
          item.type = 1;
          dataBlock = data_move(item, index);
          break;
        }
      }
      if(dataBlock.data_type){
        datas.push(dataBlock);
      }
    });
    return datas;
  }
}

function data_ok(data, index) {
  let action=""
  let id = "loc_item_" + index
  if (data.pot_type == 1) {
    action="上班"
  } else if (data.pot_type == 2) {
    action="终点"
  } else if (data.pot_type == 3) {
    let stop_time = formatSeconds(data.time_diff);
    action = "停留"+" "+stop_time
  }
  let data_type = 'ok'
  if(action == '上班'){
    data_type="start"
  }
  return {
    index:index,
    data_type: data_type,
    id:id,
    lng: data.lng,
    lat: data.lat,
    time:moment(Math.floor(data.start_time)*1000).format("HH:mm:ss"),
    timestamp:data.start_time,
    location:data.loc_name,
    action:action,
  }
}
function data_error(data, index) {
  let id = "loc_line_" + index
  let lng, lat;
  if (data.move_arr && data.move_arr.length > 1) {
    lng =
        data.move_arr[data.move_arr.length - 1].lng * 1 +
        (data.move_arr[0].lng - data.move_arr[data.move_arr.length - 1].lng) *
        0.5;
    lat =
        data.move_arr[data.move_arr.length - 1].lat * 1 +
        (data.move_arr[0].lat - data.move_arr[data.move_arr.length - 1].lat) *
        0.5;
  }
  let errorAction =
      data.need_loc == 1 ? '失联' : '休息';
  let rest_time = switchTimeLength(data.time_diff);
  return {
    id:id,
    data_type: "error",
    index:index,
    lng: lng,
    lat: lat,
    time:moment(Math.floor(data.start_time)*1000).format("HH:mm:ss"),
    timestamp:data.start_time,
    location:data.loc_name,
    action:errorAction,
    errorTime:rest_time
  }
}
function data_move(data, index) {
  let id= "loc_line_" + index
  return {
    id:id,
    index:index,
    data_type: "move",
    lng: data.lng,
    lat: data.lat,
    time:moment(Math.floor(data.move_arr[0].end_time)*1000).format("HH:mm:ss"),
    timestamp:data.start_time,
    location:data.loc_name,
    action:data.move_data.time_line_move_str,
  }
}
function data_work(data, index) {
  return {};
}
function data_warning(data, index) {
  return {};
}
