
import cheapRuler from 'cheap-ruler'
import switchTS2time from "../../tool/switchTS2time";
const ruler = new cheapRuler(37.61, "meters");

export default class AnimData {
  static Enum = {
    type_error_start: 1,
    type_error_end: 2,
    type_stop_start: 3,
    type_stop_end: 4,
    type_move: 5
  };
  constructor(transform_data, props) {
    this.transform_data = transform_data;
    this.car_no_use_yingyan = props.car_no_use_yingyan || 0;
    this.is_use_speed_detail = props.is_use_speed_detail || 0;
    this.rePlay = props.rePlay || 0;
  }
  handle() {
    return this.initTrack(this.transform_data);
  }

  handleNew() {
    let anim_move_data = [];
    console.time("处理动画点");
    for (let data of this.transform_data) {
      let transform_points;
      if (data.hasOwnProperty("move_arr")) {
        if (data.move_arr.length === 0) continue;
        if (data.pot_type === 1) {
          anim_move_data.push([data.lng, data.lat]);
          continue;
        }
        data.move_arr.forEach((item, index) => {
          if (data.move_arr[index + 1]) {
            let next_item = data.move_arr[index + 1];
            let coords = [item, next_item].map(citem => [
              citem.lng * 1,
              citem.lat * 1
            ]);
            let time_diff = next_item.start_time - item.end_time;
            if (time_diff > 0) {
              transform_points = this.splitLines(coords, time_diff, "move");
              anim_move_data = [...anim_move_data, ...transform_points];
            } else {
              anim_move_data = [...anim_move_data, [Number(item.lng), Number(item.lat)]];
            }
          }
        });
      } else if (
        data.time_diff > 0 &&
        (data.pot_type === 3 || data.pot_type === 1)
      ) {
        transform_points = this.splitLines(
          [[data.lng, data.lat]],
          data.time_diff,
          "stop"
        );
        anim_move_data = [...anim_move_data, ...transform_points];
      }
    }
    console.timeEnd("处理动画点");
    let result = anim_move_data
      .filter(item => item)
      .map(item => {
        return [item[0] * 1, item[1] * 1];
      });
    return result;
  }
  getTimeStep(time_diff) {
    // const STEP_RATIO = cc.store.page_params.is_reback_video ? 10 : 1;
    const STEP_RATIO = 1;
    return parseInt(Math.max(1, time_diff * STEP_RATIO));
  }
  splitLines(coords, time_diff, type = "move") {
    if (type === "move") {
    }
    let step = this.getTimeStep(time_diff);
    let points = [];
    if (type === "move") {
      let len = ruler.lineDistance(coords);
      let per_len = len / step;
      for (let i = 0; i < step; i++) {
        let newSpot = ruler.along(coords, per_len * i);
        points.push(newSpot);
      }
    } else if (type === "stop") {
      for (let i = 0; i < step; i++) {
        points.push(coords[0]);
      }
    } else {
    }
    return points;
  }
  initTrack(transform_data) {
    let tempData = [];
    transform_data.forEach((n,index)=>{
      if (n.pot_type == 1) {
        //起点，直接加上去
        tempData.push({
          id: n.id,
          lat: parseFloat(n.lat),
          lng: parseFloat(n.lng),
          time: n.start_time, //如果用last_time 起点停留就算不到停留时间
          timeStr: switchTS2time(n.start_time),
          type: AnimData.Enum.type_move,
          speed: n.speed,
          address: n.loc_name,
          car_total_distance: n.car_total_distance,
          car_cur_distance: n.car_cur_distance,
          car_cur_loc_distance: n.car_cur_loc_distance,
          car_direction: n.car_direction,
          car_direction_value: n.car_direction_value,
          gas: n.gas,
          server_time: n.server_time,
          gps_time: n.gps_time,
          chepai: n.chepai,
          car_type: n.car_type,
          carload_id: n.carload_id,
          sim_id: n.sim_id,
          oil: n.oil,
          water: n.water,
          water_continue: n.water_continue,
          transform_index:index

        });
      } else if (
          n.pot_type == 7 ||
          n.pot_type == 11 ||
          n.pot_type == 12 ||
          n.pot_type == 13
      ) {
        //移动点，线

        let move_arr = n.move_arr;
        for (let i = 0; i < move_arr.length; i++) {
          let last_data = tempData[tempData.length - 1];
          let move_data = move_arr[i];
          let moveDataLastTime = move_data.last_time;
          if (i == move_arr.length - 1) {
            moveDataLastTime = move_data.start_time;
          }

          if (
              !this.car_no_use_yingyan &&
              this.is_use_speed_detail &&
              transform_data[index - 1].pot_type != 5
          ) {
            if (last_data.time && moveDataLastTime - last_data.time >= 40) {
              let add_num =
                  parseInt((moveDataLastTime - last_data.time) / 20) - 1;
              let lat_diff = (move_data.lat - last_data.lat) / add_num;
              let lng_diff = (move_data.lng - last_data.lng) / add_num;
              // let cur_distance_diff = (move_data.car_cur_distance - last_data.car_cur_distance) / add_num;
              let cur_distance_diff =
                  (move_data.car_cur_loc_distance -
                      last_data.car_cur_loc_distance) /
                  add_num;
              let total_distance_diff =
                  (move_data.car_total_distance - last_data.car_total_distance) /
                  add_num;
              let gas_diff = (move_data.gas - last_data.gas) / add_num;
              for (let c = 1; c <= add_num; c++) {
                let need_time = last_data.time + 20 * c;

                tempData.push({
                  id: last_data.id + 1,
                  lat: last_data.lat + lat_diff * c,
                  lng: last_data.lng + lng_diff * c,
                  time: need_time,
                  timeStr: switchTS2time(need_time),
                  time_str: switchTS2time(need_time),
                  type: AnimData.Enum.type_move,
                  speed: last_data.speed,
                  address: last_data.address,
                  car_total_distance:
                      last_data.car_total_distance + cur_distance_diff * c,
                  car_cur_distance:
                      last_data.car_cur_distance + total_distance_diff * c,
                  car_cur_loc_distance:
                      last_data.car_cur_loc_distance + total_distance_diff * c,
                  car_direction: last_data.car_direction,
                  car_direction_value: last_data.car_direction_value,
                  gas: last_data.gas + gas_diff * c,
                  server_time: dateFormat2TimeString(new Date(need_time * 1000)),
                  gps_time: dateFormat2TimeString(new Date(need_time * 1000)),
                  chepai: last_data.chepai,
                  car_type: last_data.car_type,
                  carload_id: last_data.carload_id,
                  sim_id: last_data.sim_id,
                  oil: n.oil,
                  water: n.water,
                  water_continue: n.water_continue,
                  transform_index:index
                });
              }
            }
          }
          tempData.push({
            id: move_data.id,
            lat: parseFloat(move_data.lat),
            lng: parseFloat(move_data.lng),
            time: moveDataLastTime,
            timeStr: switchTS2time(moveDataLastTime),
            type: AnimData.Enum.type_move,
            speed: move_data.speed,
            address: move_data.loc_name,
            car_total_distance: move_data.car_total_distance,
            car_cur_distance: move_data.car_cur_distance,
            car_cur_loc_distance: move_data.car_cur_loc_distance,
            car_direction: move_data.car_direction,
            car_direction_value: move_data.car_direction_value,
            gas: move_data.gas,
            server_time: move_data.server_time,
            gps_time: dateFormat2TimeString(new Date(moveDataLastTime * 1000)),
            chepai: move_data.chepai,
            car_type: move_data.car_type,
            carload_id: move_data.carload_id,
            sim_id: move_data.sim_id,
            oil: move_data.oil,
            water: move_data.water,
            water_continue: move_data.water_continue,
            transform_index:index

          });
        }
      } else if (n.pot_type == 3) {
        let last_data = tempData[tempData.length - 1];
        if (
            !this.car_no_use_yingyan &&
            this.is_use_speed_detail &&
            transform_data[index - 1].pot_type != 5
        ) {
          if (last_data.time && n.last_time - last_data.time >= 40) {
            let add_num = parseInt((n.last_time - last_data.time) / 20) - 1;
            let start_time = last_data.time;
            for (let c = 1; c <= add_num; c++) {
              let need_time = start_time + c * 20;
              tempData.push({
                id: last_data.id + 1,
                lat: parseFloat(n.lat),
                lng: parseFloat(n.lng),
                // time: need_time,
                time: need_time,
                timeStr: switchTS2time(need_time),
                // timeStr: n.time,
                type: AnimData.Enum.type_stop_start,
                speed: 0,
                address: last_data.address,
                car_total_distance: last_data.car_total_distance,
                car_cur_distance: last_data.car_cur_distance,
                car_cur_loc_distance: last_data.car_cur_loc_distance,
                car_direction: last_data.car_direction,
                car_direction_value: last_data.car_direction_value,
                gas: last_data.gas,
                server_time: dateFormat2TimeString(new Date(need_time * 1000)),
                gps_time: dateFormat2TimeString(new Date(need_time * 1000)),
                chepai: last_data.chepai,
                car_type: last_data.car_type,
                carload_id: last_data.carload_id,
                sim_id: last_data.sim_id,
                oil: n.oil,
                water: n.water,
                trash_pot_id: n.trash_pot_id,
                water_continue: n.water_continue,
                transform_index:index
              });
            }
          }
        }

        let timeStr = "";
        if (this.rePlay) {
          timeStr = n.time;
        } else {
          timeStr = switchTS2time(n.last_time);
        }
        tempData.push({
          id: n.id,
          lat: parseFloat(n.lat),
          lng: parseFloat(n.lng),
          time: n.last_time,
          timeStr: timeStr,
          type: AnimData.Enum.type_stop_start,
          speed: 0,
          address: n.loc_name,
          car_total_distance: n.car_total_distance,
          car_cur_distance: n.car_cur_distance,
          car_cur_loc_distance: n.car_cur_loc_distance,
          car_direction: n.car_direction,
          car_direction_value: n.car_direction_value,
          gas: n.gas,
          server_time: n.server_time,
          gps_time: dateFormat2TimeString(new Date(n.last_time * 1000)),
          chepai: n.chepai,
          car_type: n.car_type,
          carload_id: n.carload_id,
          sim_id: n.sim_id,
          oil: n.oil,
          water: n.water,
          trash_pot_id: n.trash_pot_id,
          water_continue: n.water_continue,
          transform_index:index
        });
      } else if (n.pot_type == 2) {
        //终点
      }
    });

    let has_time_str = "";

    for (let i = 0; i <= tempData.length - 1; i++) {
      let data = tempData[i];

      if (data.address == "未知") {
        tempData.splice(i, 1);
      }

      if (has_time_str == data.timeStr) {
        for (let j = i; j <= tempData.length - 1; j++) {
          if (tempData[j].timeStr != has_time_str) {
            break;
          }
          tempData[j].time += 1;
          tempData[j].timeStr = switchTS2time(tempData[j].time);
          tempData[j].gps_time = dateFormat2TimeString(
              new Date(tempData[j].time * 1000)
          );
        }
        has_time_str = tempData[i].timeStr;
      } else {
        has_time_str = data.timeStr;
      }
    }
    console.log(tempData);
    return tempData;
  }

}
function dateFormatValue(value) {
  if (value <= 9) {
    return "0" + value;
  } else {
    return value;
  }
}
function dateFormat2TimeString(date) {
  var str =
    date.getFullYear() +
    "-" +
    dateFormatValue(date.getMonth() + 1) +
    "-" +
    dateFormatValue(date.getDate()) +
    " " +
    dateFormatValue(date.getHours()) +
    ":" +
    dateFormatValue(date.getMinutes()) +
    ":" +
    dateFormatValue(date.getSeconds());
  return str;
}
