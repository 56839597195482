
import Track3D from "./Animate/Track3D";

const baseUrl = process.env.VUE_APP_TRACK_BASEURL;
import PathAnimCtrl from "./Animate/PathAnimCtrl";
import moment from "moment";
import AnimData from "./Animate/AnimData";
export default {
  name: "path-ctrl",
  components: {},
  props:{

  },
  data() {
    return {
      baseUrl: baseUrl,
      data: {},
      status: "pause", //播放状态
      is_reback_video: "",
      roleAttr: {
        role_attr: 0
      },
      is_use_speed_detail: "",
      progress:  '<span class="progress_bar_green" style="width:100%;background:#b7b7b7"></span>',
      pathAnimCtrl: null,

      progress_start_time: "",
      progress_end_time: "",

      prgressText: "00:00:00", //当前时间轴时间
      progress_left: 0,
      speed: 1,
      transformData:[],
      pathAnimLayerLayer:null,
      trackLayer:null,
      speed_show:false,
    };
  },

	beforeDestroy() {
		this.endPlay();
	},
	methods: {
    showSpeedSel(){
      this.speed_show = !this.speed_show;
    },
    startPlay() {
      // this.$store.state.track.map.trackLayer.resetElement();
      this.pathAnimCtrl.trackplay();
    },

    endPlay() {
      if(!this.pathAnimCtrl){
        return;
      }
      this.pathAnimCtrl.trackstop();
      this.speedCtrl(1);
    },
    status_play() {
      this.status = "play";
    },
    status_pasue() {
      this.status = "pasue";
    },

    track_anim_progress_change(obj) {
      let progress = obj.percent,
          time = obj.time;
      this.prgressText = moment(time * 1000).format("HH:mm:ss");
      let maxW =
          this.$refs.video_progress.clientWidth ||
          this.$refs.video_progress.offsetWidth;
      this.progress_left = maxW * progress;
    },
    track_progress_change(obj) {
      this.progress_left = obj.progress_left;
      this.prgressText = moment(obj.time * 1000).format("HH:mm:ss");
      console.log(this.prgressText);
    },
    track_table_scroll(props) {
      //轨迹index变化之后的回调
    },
    on_track_play() {
      this.track_anim_start();
    },
    on_track_stop() {
      this.status = 'pasue';
      if (this.pathAnimLayerLayer) {
        this.$emit("track_stop");
        this.pathAnimLayerLayer.stop();
       this.trackLayer.fitBounds(true);
      }
    },
    track_anim_start() {
      this.$emit("track_play");
      console.log("轨迹开始播放了");
      this.pathAnimLayerLayer.start("1");
    },
    track_anim_restart() {
      this.pathAnimLayerLayer.cur_index = 0;
      this.pathAnimLayerLayer.tween.progress(0);
    },
    track_anim_recover() {
      this.pathAnimLayerLayer.resume();
    },
    track_anim_pasue() {
      this.pathAnimLayerLayer.pause();
    },

    init_progress_re_render({transformData,trackLayer,mapObj}) {
      let anim = new AnimData(transformData, {});
      let animData = anim.handle();
      let anim_move_data = anim.handleNew();

      this.transformData = transformData;
      this.trackLayer = trackLayer;
      this.pathAnimCtrl = new PathAnimCtrl({ vue: this });


      this.pathAnimLayerLayer = new Track3D({
        vue:this,
        mapObj: mapObj,
        track_data: {
          transform_data: this.transformData,
          anim_data: animData,
          anim_move_data: anim_move_data
        }
      });

      this.pathAnimCtrl.setPathAnimLayerLayer(this.pathAnimLayerLayer);
      this.pathAnimCtrl.setTrackLayer(this.trackLayer);
      this.pathAnimLayerLayer.setPathAnimCtrl(this.pathAnimCtrl);

      this.pathAnimLayerLayer.setCoordinates(this.transformData);

      let html = this.pathAnimCtrl.progress_re_render(this.transformData,animData)
      if(!html){
        this.progress ='<span class="progress_bar_green" style="width:100%;background:#b7b7b7"></span>'
      }else{
        this.progress =html
      }

      if (transformData.length) {
        this.roleAttr.role_attr = 1;
        this.progress_start_time = moment(
            Math.floor(transformData[0].start_time) * 1000
        ).format("HH:mm:ss");
        this.progress_end_time = moment(
            Math.floor(transformData[transformData.length - 1].end_time) * 1000
        ).format("HH:mm:ss");
      } else {
        this.roleAttr.role_attr = 0;
      }

    },
    progress_bg(e) {
      function getOffset(Node, offset) {
        if (!offset) {
          offset = {};
          offset.top = 0;
          offset.left = 0;
        }
        if (Node == document.body) {
          //当该节点为body节点时，结束递归
          return offset;
        }
        offset.top += Node.offsetTop;    offset.left += Node.offsetLeft;
        if(!Node.offsetParent){
          return offset;
        }
        return getOffset(Node.offsetParent, offset);//向上累加offset里的值
      }

      // this.pathAnimLayerLayer = this.$store.state.track.map.pathAnimLayerLayer;
      //点击了进度条
      let maxW =
          this.$refs.video_progress.clientWidth ||
          this.$refs.video_progress.offsetWidth;
      let left = e.pageX - this.$refs.progress_bg.getBoundingClientRect().x;
      this.pathAnimCtrl.progress_bg(left, maxW);
    },
    setStartByProgress(progress) {
     this.pathAnimLayerLayer.setStartByProgress(progress);
    },
    table_scroll_drag(percents) {
      console.log(percents);
    },
    progress_btn(e) {
      this.pathAnimCtrl.progress_btn(e);
    },
    speedCtrl(speed) {
      this.speed_show = false;
      this.speed = speed;
     this.pathAnimLayerLayer.timeScale(speed);
    }
  },
  created() {
  }
};
