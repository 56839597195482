import formatSeconds from "../tool/formatSeconds";
import switchTS2time from "../tool/switchTS2time";
import {getLayerServer} from 'xb-lib-view/dist/utils';
import { getMapClass } from "../adapter/adapter-three";

export default class Marker {
  static Enum = {
    track_start: 1,
    track_end: 2,
    track_stop: 7, //停留点
    track_point: 8, //一个点
    track_his_end_point: 9 //结束位置
  };
  markerIndex = 1;
  lastMarkerObj = null;
  markerzIndex = 1;
  constructor(props) {
    this.mapObj = props.mapObj;
    this.trackLayer = props.trackLayer;
    this.data = props.data;
    this.type = props.type;
    this.index = props.index;
    this.infoWindow = null;
  }
  getResource() {
    let data = this.data;
    let type = this.type;

    // 记录异常或作业点的结束时间，以便画线时修改轨迹颜色
    if (data.color == "red" || data.color == "yellow") {
      cc.store.track_status = {
        color: data.color,
        pass_time: data.end_time - data.start_time
      };
    }

    const baseUrl = process.env.VUE_APP_TRACK_BASEURL;

    let infoWindowOffset = 0;
    let offset = 0;
    let markerContent = null;
    let iconval = require("./imgs/icon_1.0_0.1_dqwz.png");
    let iconval_s = iconval;
    let markerSpan;
    let initzIndex = this.markerzIndex++;

    if (type == Marker.Enum.track_start) {
      iconval = require("./imgs/icon_1.0_0.1_qd.png"); //57*44
      iconval_s = require("./imgs/icon_1.0_0.1_qd.png");
      markerContent = null;
    } else if (type == Marker.Enum.track_end) {
      iconval = require("./imgs/icon_1.0_0.1_zd.png"); //57*44
      iconval_s = require("./imgs/track_map2_end_s.png");
      markerContent = null;
    } else if (type == Marker.Enum.track_stop) {
      iconval = require("./imgs/stop.png");  //./imgs/track_map2_stay.png
      iconval_s = require("./imgs/stop.png"); //./imgs/track_map2_stay_s.png
    } else if (type == Marker.Enum.track_point) {
      iconval = require("./imgs/track_map2_point_s.png");
      iconval_s = require("./imgs/track_map2_point_s.png");
      markerContent = null;
    } else if (type == Marker.Enum.track_his_end_point) {
      iconval = require("./imgs/icon_1.0_0.1_dqwz.png");
      iconval_s = require("./imgs/icon_1.0_0.1_dqwz.png");
      markerContent = null;
    }

    if (data.trash_pot_id && type == Marker.Enum.track_stop) {
       iconval = require("./imgs/stop.png");
      iconval_s = require("./imgs/stop.png");
    }

    let el = document.createElement("div");
    el.className = "map_track_marker";
    el.innerHTML = `<img class="iconval" src="${iconval}" /><img class="iconval_s" style="display:none;" src="${iconval_s}" />`;
    return el;
  }
  async createMarker() {
    let offset = new AMap.Pixel(-17,-50)
    if(this.type == Marker.Enum.track_start || this.type == Marker.Enum.track_end){
      offset=  new AMap.Pixel(-28,-44)
    }
		let MapClass = getMapClass();
		console.log('MapClass',MapClass)
    this.marker = new MapClass.Marker({
      content: this.getResource(),
      anchor:"top-left",
      offset:offset,
	    position: [this.data.lng, this.data.lat]

    });
    this.setPop(this.type);
  }
  clear() {
    if (this.marker) {
      this.marker.setMap(null);
    }
    if (this.infoWindow) {
      this.mapObj.clearInfoWindow?.(this.infoWindow);
	    this.infoWindow.setMap?.(null);
    }
  }

  setPop(type) {
    let data = this.data;
    let el = document.createElement("div");
    el.className = "map_track_marker_pop";
    // el.style["max-width"] = "220px";
    // el.style["margin-bottom"] = "50px";
    // el.style["background"] = "#fff";
    // el.style["border-radius"] = "5px";
    // el.style["padding"] = "5px";
    // el.style["border"] = "1px solid #e1e1e1";

    el.style["padding"] = "12px 20px";
    el.style["color"] = "#fff";
    el.style["font-size"] = "16px";


    let info = [];
    let cur_date = switchTS2time(data.last_time, 3);
    if (data.time_diff == 0) {
      if (type == Marker.Enum.track_start) {
        info.push("<b style=' color: rgba(255,255,255,0.7);margin: 8px 0'>起点 :" + cur_date + data.time + "</b>");
      } else if (type == Marker.Enum.track_end) {
        info.push("<b style=' color: rgba(255,255,255,0.7);margin: 8px 0'>终点 :" + cur_date + data.time + "</b>");
      } else {
        info.push("<b style=' color: rgba(255,255,255,0.7);margin: 8px 0'>上报时间 : " + cur_date + data.time + " </b>");
      }
    } else {
      let move_time = formatSeconds(data.time_diff);
      let stop_time = move_time;

      let msg_str =
        "<b>停留 : " +
        stop_time +
        " （" +
        cur_date +
        data.time +
        "~" +
        cur_date +
        data.loc_time +
        "）</b>";

      info.push(msg_str);
    }
    let xujia = "位置：";
    if (data.loc_type == 5) {
      xujia = '<span class="red">[虚假位置]</span>';
    }
    info.push(xujia + data.loc_name);
    el.innerHTML = info.join("<br />");
	  let MapClass = getMapClass();
    this.infoWindow = new MapClass.InfoWindow({
      isCustom: true, //使用自定义窗体
      content: el,
	    position: [0,0],
	    
    });

    //做弹窗的开关
    this.mapObj.on("click", e => {
      this.mapObj.clearInfoWindow?.(this.infoWindow);
	    this.infoWindow.setMap?.(null);
    });
	  this.marker.element?.addEventListener?.('click',this.active.bind(this))
    this.marker.on?.('click',this.active.bind(this));
  }
  resetMarker() {
    if (this.infoWindow) {
      this.mapObj.clearInfoWindow?.(this.infoWindow);
	    this.infoWindow.setMap?.(null);
    }
  }
  resetAllLine() {
    this.trackLayer.resetElement();
	  let {lng, lat} = this.marker.getPosition();
  }
  active(e) {
    e && e.stopPropagation && e.stopPropagation();
    this.resetAllLine();
    //同一时间只能由一个infoWindow 并且 mapbox 必须把实例传进去才能清除
	
	  let {lng, lat} = this.marker.getPosition();
    // this.mapObj.panTo(this.data);
    this.mapObj.clearInfoWindow?.(this.infoWindow);
	  this.infoWindow.setMap?.(null);
    setTimeout(() => {
      this.infoWindow.open?.(this.mapObj, this.marker.getPosition());
	    this.infoWindow.setMap?.(this.mapObj);
	    this.infoWindow.setPosition?.([Number(lng), Number(lat)]);
    },1);

  }
  render() {
    this.marker.setMap(this.mapObj);
  }
}
