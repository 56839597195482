export const waitUntilOk = (condition = null,timeout = 1000,interval = 50) =>{
	condition = condition || function (){};
	let start_time = new Date().getTime();
	return new Promise((resolve,reject) => {
		let interval_i = setInterval(()=>{
			if(condition()){
				clearInterval(interval_i);
				resolve();
			}else{
				if(timeout > 0 && new Date().getTime() - start_time > timeout){
					reject();
				}
			}
		},interval);
	})
}