import ci9xwhqkcroflq4kqyyw from '@/cLibrary/project/曲靖马龙/ci9xwhqkcroflq4kqyyw/ci9xwhqkcroflq4kqyyw.vue';
export default {
    components: {
        ci9xwhqkcroflq4kqyyw
    },
    created() {
        this.initContent()
    },
    methods: {
        initContent() {
	    	this.P4ZQl3T.content = '联系方式'
        }
    },
}