import { onMounted, reactive,ref } from "@vue/composition-api";


export function useTrack ({uid,com_id,trackApi}) {
	let track_ref = ref(null);
	
	const loadData = (uid,com_id) => {
		track_ref.value.loadData(uid,com_id)
	}
	onMounted(async ()=>{
		console.log('track_ref',track_ref.value);

		track_ref.value.setMap(window.curMapManager.mapObj)
		track_ref.value.setTrackApi(trackApi)
		loadData(uid,com_id)
	})
	return {
		track_ref,
		loadData
	};
}