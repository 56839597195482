
     import moment from 'moment'
    export default {
        name: "eventBox",
        props:{
            eventData:{
                type:Array,
                default:()=>[]
            }
        },
        data(){
            return {}
        },
        methods:{
            seeDetail(item){
              vuecc.bus.$emit('case-detail-pop',{id:item.case_id});
            },
            time(time){
                return moment(Math.floor(time)*1000).format("HH:mm:ss")
            },
            eventClick(e){
                e.stopPropagation()
            }
        }
    }
