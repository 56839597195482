import formatSeconds from "../tool/formatSeconds";
import switchTS2time from "../tool/switchTS2time";
import switchTimeLength from "../tool/switchTimeLength.js";

import moment from "moment";
import { toMapLngLat } from "../adapter/adapter-three";

let line_index_i = 0;
let is_multi_day = false;
export default class TransformData {
  constructor(props) {
    this.data = props;
  }
  handle = function() {
	  this.data.data = this.data.data.map(item => {
		  let [lng, lat] = toMapLngLat(item);
		
		  return {
			  ...item,
			  lng,
			  lat
		  }
	  })
    return transform_data(this.data.data, this.data.today_is_end);
  };
}
const copyData = (new_data,old_data) => {
  return {...new_data,...old_data};
}
function transform_data(data, today_is_end) {
  line_index_i = 0;
  let line_arr = data;
  //pot_type  1起点，2终点，3停点，4虚点，5.断点，6异常点，7,移动点,8业务点,9,上班,10,下班 //作业11，违规12，行驶13的切换点
  let start = 0;
  let data_arr = [];
  let start_move = false;
  let start_pot = null;
  let move_arr = [];
  let point_color_key = 0;
  for (let i = 0; i < data.length; ++i) {
    let item = copyData({}, data[i]);
    let prev_item = copyData({}, data[i - 1]);
    let next_item = copyData({}, data[i + 1]);
    if (!start) {
      //找起1始点
      if (item.status == 1 && item.loc_type != 5) {
        start = 1;
        // console.log('起点');
        data_arr.push(item);
        start_move = true;
        start_pot = item;
        if (item.time_diff > 0) {
          //添加衔接点，使用下一个点的点类型
          let times = moment(
            (Math.floor(moment("2000-01-01 " + item.time).format("X")) +
              Math.floor(item.time_diff)) *
              1000
          ).format("HH:mm");
          let next_start = {
            ...item,
            ...{
              start_time: item.end_time,
              time: times,
              loc_time: times,
              pot_range: next_item.pot_range
            }
          }; //衔接点，因为id会跟下一个点重复，所以这个点在绘制动画的时候会被剔除
          move_arr.push(next_start);
        } else {
          move_arr.push({ ...item });
        }
        item.pot_type = 1;
        continue;
      }
    }

    if (data.length - 1 == i && today_is_end) {
      //2终点
      if (item.status == 1 && item.loc_type != 5) {
        if (prev_item && (prev_item.status == 1 && prev_item.loc_type != 5)) {
          let inset_pot = {};
          if (item.time_diff > 0) {
            //最后结束点用开始时间填充最后一段线的最后一个点
            inset_pot = {
              ...item,
              start_time: item.start_time,
              end_time: item.start_time,
              last_time: item.start_time
            };
          } else {
            inset_pot = item;
          }
          move_arr.push(inset_pot);
          console.log("终点addlinedata");
          add_line_data(data_arr, move_arr, line_arr, i);
        }
        item.pot_type = 2;
        data_arr.push(item);
        continue;
      }
    }
    //正常点
    if (item.status == 1 && item.loc_type != 5) {
      if (!start_move) {
        start_move = true;
        start_pot = item;
      }
      if (item.time_diff == 0) {
        //在这里追加切割判断
        if (Array.isArray(item.business) && item.business.length > 0) {
          //业务点
          // console.log("业务点");
          if (prev_item && (prev_item.status == 1 && prev_item.loc_type != 5)) {
            move_arr.push(item);
            console.log("业务点addlinedata");
            add_line_data(data_arr, move_arr, line_arr, i);
          }

          item.pot_type = 8;
          data_arr.push(item);
          start_move = false;
          move_arr = [item];
        } else {
          if (start_move) {
            move_arr.push(item);
          }
          if (
            !next_item ||
            (next_item.status != 1 || next_item.loc_type == 5)
          ) {
            //5断点
            // console.log("断点2");
            if (
              prev_item &&
              (prev_item.status == 1 && prev_item.loc_type != 5)
            ) {
              console.log("断点2addlinedata");
              //断点前也要判断pot_range
              if (prev_item.pot_range) {
                let breakFlag = true;
                //pot_range  1:作业  2违规  3行驶
                move_arr.push(item);
                add_line_data(
                  data_arr,
                  move_arr,
                  line_arr,
                  i,
                  Math.floor(prev_item.pot_range) + 10
                );
                item.pot_type = Math.floor(prev_item.pot_range) + 10;
                start_move = true;
                i == data.length - 2 ? (move_arr = []) : (move_arr = [item]);
              } else {
                add_line_data(data_arr, move_arr, line_arr, i);
                item.time_pos = 1;
              }
            } else {
              item.time_pos = 3;
            }
            item.pot_type = 5;

            data_arr.push(item);
            start_move = false;
            move_arr = [];
          } else if (
            !prev_item ||
            (prev_item.status != 1 || prev_item.loc_type == 5)
          ) {
            //5断点
            // console.log("断点1");
            item.pot_type = 5;
            item.time_pos = 2;

            data_arr.push(item);
            move_arr = [
              {
                ...next_item,
                lng: item.lng,
                lat: item.lat,
                end_time: item.start_time,
                start_time: item.start_time,
                last_time: item.start_time,
                time: item.time,
                loc_time: item.time,
                add_pot_type: true
              }
            ];
          } else {
            //追加判断在这里
            let breakFlag = item.pot_range != prev_item.pot_range;
            //正常点前面有衔接点需要添加一段移动线段
            //pot_range  1:作业  2违规  3行驶
            if (breakFlag && prev_item.pot_range) {
              move_arr.push(item);
              add_line_data(
                data_arr,
                move_arr,
                line_arr,
                i,
                Math.floor(prev_item.pot_range) + 10
              );
              item.pot_type = Math.floor(prev_item.pot_range) + 10;
              start_move = true;
              move_arr = [item];
            }
          }
        }
      } else {
        //3停点  //停点前面的路径需要截断切割
        console.log("停点");
        if (prev_item && (prev_item.status == 1 && prev_item.loc_type != 5)) {
          if (prev_item.pot_range) {
            //pot_range  1:作业  2违规  3行驶
            //停点前的路径切割
            let inset_pot = {
              ...prev_item,
              ...{
                lng: item.lng,
                lat: item.lat,
                end_time: item.start_time,
                start_time: item.start_time,
                last_time: item.start_time,
                time: item.time,
                loc_time: item.time,
                add_pot_type: true
              }
            };
            move_arr.push(inset_pot);
            if (prev_item.pot_range == 1) {
              //作业点切换
              add_line_data(data_arr, move_arr, line_arr, i, 11);
              item.pot_type = 11;
            } else if (prev_item.pot_range == 2) {
              //违规点切换
              if (
                prev_item &&
                next_item &&
                prev_item.pot_range == next_item.pot_range
              ) {
                item.pot_range = 2;
                item.all_status = prev_item.all_status;
                continue;
              } else {
                add_line_data(data_arr, move_arr, line_arr, i, 12);
                item.pot_type = 12;
              }
            } else if (prev_item.pot_range == 3) {
              //行驶点切换
              add_line_data(data_arr, move_arr, line_arr, i, 13);
              item.pot_type = 13;
            }
            start_move = true;
          } else {
            // if (prev_item.time_diff > 0)
            //正常行驶到没有pot_range的点会进到这里，在停留点前补一个行驶点
            move_arr.push({
              ...item,
              ...{
                start_time: item.start_time,
                last_time: item.start_time,
                time: item.time,
                loc_time: item.time,
                add_pot_type: true,
                time_diff: 0
              }
            });
            console.log("停点addlinedata");
            add_line_data(data_arr, move_arr, line_arr, i);
            start_move = false;
            // move_arr=[item]
          }
        }
        item.pot_type = 3;
        data_arr.push(item);
        start_move = false;
        if (next_item && next_item.pot_type != 2) {
          let next_start = {
            ...item,
            ...{
              start_time: item.end_time,
              last_time: item.end_time,
              time: item.loc_time,
              loc_time: item.loc_time,
              add_pot_type: true,
              pot_range: next_item.pot_range,
              all_status: next_item.all_status,
              time_diff: 0,
              pot_type: 13
            }
          }; //衔接点，因为id会跟下一个点重复，所以这个点在绘制动画的时候会被剔除
          move_arr = [next_start];
        } else {
          move_arr = [item];
        }
      }
    } else {
      //status !=1  || pot_type == 5
      move_arr = [];
      //4虚点
      if (item.loc_type == 5) {
        item.pot_type = 4;
        data_arr.push(item);
      } else {
        //6异常点
        if (item.need_loc == 1) {
          item.line_color_type = 2;
        } else {
          item.line_color_type = 3;
        }
        item.move_arr = [];
        // 休息的路徑？？？？？？？？？？？ 或者失聯
        if (prev_item && (prev_item.status == 1 && prev_item.loc_type != 5)) {
          if (next_item && (next_item.status == 1 && next_item.loc_type != 5)) {
            // if (prev_item.lng != next_item.lng) {  bug记录，上下点一样的失联被抛出时间轴了 失联上下两点时间使用上点的结束与下点的开始，避免上下有停留点错误计算时间
            let insertPrev = {
              ...prev_item,
              start_time: prev_item.end_time,
              end_time: prev_item.end_time,
              last_time: prev_item.end_time,
              time_diff: 0,
              time: prev_item.loc_time,
              loc_time: prev_item.loc_time
            };
            let insertNext = {
              ...next_item,
              start_time: next_item.start_time,
              end_time: next_item.start_time,
              last_time: next_item.start_time,
              time_diff: 0,
              time: next_item.time,
              loc_time: next_item.time
            };
            item.move_arr = [insertPrev, insertNext];
            // }
          }
        }
        item.pot_type = 6;
        handleMoveData(item);
        data_arr.push(item);
      }
    }
  }
  //处理行驶时间
  let drive_time = 0;
  for (let i = 0; i < data_arr.length; ++i) {
    if (
      data_arr[i].pot_type == 7 ||
      data_arr[i].pot_type == 11 ||
      data_arr[i].pot_type == 12 ||
      data_arr[i].pot_type == 13
    ) {
      let drive_arr = data_arr[i].move_arr;
      if (drive_arr.length < 2) {
        continue;
      }
      let last_data = drive_arr[drive_arr.length - 1];
      drive_time += last_data.end_time - drive_arr[0].end_time;
    }
  }
  console.log("data_arr:", data_arr);
  return data_arr;
}
function handleMoveData(data) {
  let move_arr = data.move_arr;
  if (move_arr.length < 2) {
    return;
  }
  let last_data = move_arr[move_arr.length - 1];
  // let time_sec = last_data.start_time - move_arr[0].end_time;
  let last_fullTime = parseInt(last_data.last_time);
  let first_fullTime = parseInt(move_arr[0].last_time);

  let move_time = formatSeconds(last_fullTime - first_fullTime);

  let movestr = "";
  let movestr_s = "";
  let cur_date = switchTS2time(data.last_time, 3, is_multi_day);
  let last_data_date = switchTS2time(last_data.last_time, 3, is_multi_day);
  if (data.line_color_type == 2 || data.line_color_type == 3) {
    movestr = data.error_msg;
    movestr_s = data.error_msg;
    if (
      is_multi_day &&
      data.error_msg_with_date !== undefined &&
      data.error_msg_with_date !== ""
    ) {
      movestr_s = data.error_msg_with_date;
    }
  } else {
    movestr = "移动 " + move_time;
    movestr_s =
      "<b>移动 " +
      move_time +
      " (" +
      cur_date +
      data.loc_time +
      "~" +
      last_data_date +
      last_data.loc_time +
      ")</b>";
  }

  if (data.pot_type == 6 && data.line_color_type != 3) {
    movestr_s += "</br>失联时长" + switchTimeLength(data.time_diff);
  }

  data.move_data = {
    map_line_move_str: movestr_s,
    time_line_move_str: movestr,
    s_time: last_data.loc_time,
    e_time: data.loc_time
  };
}
function add_line_data(data_arr, move_arr, line_arr, i, pt) {
  if (move_arr.length > 1) {
    let new_pot = copyData({}, move_arr[0]);
    //            console.log(new_pot);
    if (pt) {
      new_pot.pot_type = pt;
    } else if (new_pot.pot_range) {
      if (new_pot.pot_range == 1 || new_pot.pot_range == 2) {
        new_pot.pot_type = Math.floor(new_pot.pot_range) + 10;
      } else {
        new_pot.pot_type = 7;
      }
    }
    if (!new_pot.pot_type || new_pot.pot_type == 1) {
      new_pot.pot_type = 7;
    }

    new_pot.move_arr = move_arr;
    // new_pot.move_arr = getline_data(move_arr, line_arr, i);
    new_pot.id = new Date().getTime() + Math.random() * 3;

    //二次分类
    if (new_pot.pot_type == 11 || new_pot.pot_type == 12) {
      //工作点或者违规点
      handleActionData(new_pot, new_pot.pot_type, i);
    } else {
      handleMoveData(new_pot);
    }

    data_arr.push(new_pot);
  }
}
function handleActionData(itemData) {
  //分组合并
  //切分move_data
  let d_m = itemData.move_arr;
  let move_data_group = [];
  let move = false;
  for (let j = 1; j < 21; j++) {
    for (let i = 0; i < d_m.length; i++) {
      if (i == 0) {
        let this_m_d = d_m[i].all_status;
        let next_m_d = d_m[i + 1].all_status;
        let startBreakPointFlag = false;
        if (this_m_d) {
          startBreakPointFlag = this_m_d.indexOf(j) != -1;
        } else {
          if (next_m_d) {
            startBreakPointFlag = next_m_d.indexOf(j) != -1;
          } else {
            startBreakPointFlag = false;
          }
        } //开始节点
      } else {
        let prev_m_d = d_m[i - 1].all_status;
        let this_m_d = d_m[i].all_status;

        let startBreakPointFlag = false;
        if (i < d_m.length - 1) {
          if (this_m_d) {
            if (this_m_d.indexOf(j) != -1) {
              if (prev_m_d) {
                if (prev_m_d.indexOf(j) == -1) {
                  startBreakPointFlag = true;
                }
              } else {
                startBreakPointFlag = true;
              }
            } //开始节点
          } else {
            startBreakPointFlag = false;
          }
        } else {
          startBreakPointFlag = false;
        }

        let endBreakPointFlag = false;
        if (i < d_m.length - 1) {
          if (d_m[i + 1].all_status) {
            let next_m_d = d_m[i + 1].all_status;
            if (this_m_d) {
              endBreakPointFlag =
                this_m_d.indexOf(j) != -1 && next_m_d.indexOf(j) == -1;
            }
          } else {
            endBreakPointFlag = true;
          }
        } else {
          endBreakPointFlag = true;
        }
      }

      if (startBreakPointFlag && !move) {
        let actBlock = {
          actType: j,
          startIndex: i, //开始的索引
          startTime: d_m[i].start_time,
          start_last_time: d_m[i].last_time,
          start_time: d_m[i].time,
          start_loc_time: d_m[i].loc_time
        };
        move = true;
      } else if (endBreakPointFlag && move) {
        if (move) {
          //有开始点才有结束点
          let endIndex = i;
          if (i == d_m.length - 2) {
            endIndex = i + 1;
          }
          actBlock.endIndex = endIndex; //结束的索引
          actBlock.endTime = d_m[endIndex].end_time;
          actBlock.end_last_time = d_m[endIndex].last_time;
          actBlock.end_time = d_m[endIndex].time;
          actBlock.end_loc_time = d_m[endIndex].loc_time;
          move_data_group.push(actBlock);
          move = false;
        }
      }
    }
  }
  itemData.move_data_group = move_data_group;

  //二次分组完毕，
  //开始根据  data.move_data_group 重组move_data
  //从组完毕将适用此方法的数据从 handleMoveData 方法中移除
  let group = itemData.move_data_group;
  let move_arr = d_m;
  let all_line_move_str = "";
  let all_move_str = "";
  let s_time_group = [];
  let e_time_group = [];
  for (let x = 0; x < group.length; x++) {
    let data = move_arr[group[x].startIndex];
    let last_data = move_arr[group[x].endIndex];
    // let time_sec = last_data.start_time - move_arr[0].end_time;
    let time_sec = last_data.start_time - move_arr[0].start_time;
    let end_time = parseInt(group[x].end_last_time);
    let start_time = parseInt(group[x].start_last_time);
    let move_time = formatSeconds(end_time - start_time);

    let movestr_s = "";
    let cur_date = switchTS2time(data.last_time, 3, is_multi_day);
    let last_data_date = switchTS2time(last_data.last_time, 3, is_multi_day);
    let type_str = "";
    switch (group[x].actType) {
      case 1:
        type_str = "违规停留";
        break;
      case 2:
        type_str = "脱离工作区域";
        break;
      case 3:
        type_str = "超速";
        break;
      case 4:
        type_str = "作业超速";
        break;
      case 5:
        type_str = "偏离路线";
        break;
      case 6:
        type_str = "私自放水";
        break;
      case 7:
        type_str = "洒水车怠工";
        break;
      case 8:
        type_str = "干喷";
        break;
      case 9:
        type_str = "偷油";
        break;
      case 11:
        type_str = "扫地";
        break;
      case 12:
        type_str = "洒水";
        break;
      case 16:
        type_str = "停留不动";
        break;
      case 17:
        type_str = "未佩戴";
        break;
      case 18:
        type_str = "网络异常";
        break;
      case 19:
        type_str = "手动关机";
        break;
      case 20:
        type_str = "没电关机";
        break;
    }
    let split = "";
    if (x == group.length - 1) {
      split = "";
    } else {
      split = ",";
    }
    let movestr = type_str + move_time;
    movestr_s =
      "<b> " +
      type_str +
      "" +
      move_time +
      " (" +
      cur_date +
      data.time /*loc_time*/ +
      "~" +
      last_data_date +
      last_data.time +
      ")</b><br>";

    group[x].move_data = {
      map_line_move_str: movestr_s,
      time_line_move_str: movestr,
      s_time: last_data.loc_time,
      e_time: data.time,
      type_str: type_str
    };
    if (last_data.time_diff > 0) {
      group[x].move_data.s_time = last_data.time;
    }
    all_line_move_str += group[x].move_data.map_line_move_str + "\n";
    all_move_str += group[x].move_data.time_line_move_str + split + "\n";
    s_time_group.push(group[x].move_data.s_time);
    e_time_group.push(group[x].move_data.e_time);
  }
  // data.move_data = {map_line_move_str: movestr_s, time_line_move_str:movestr, s_time: last_data.loc_time, e_time: data.time};
  itemData.move_data = {
    map_line_move_str: all_line_move_str,
    time_line_move_str: all_move_str,
    s_time: s_time_group,
    e_time: e_time_group
  };
}
function getline_data(move_arr, line_arr) {
  //加入该段落移动路径
  let new_move_arr = [];
  let start = false;
  let i = line_index_i;
  for (i; i < line_arr.length; i++) {
    let line_item = copyData({}, line_arr[i]);
    if (start == false) {
      if (
        line_item.lng == move_arr[0].lng &&
        line_item.lat == move_arr[0].lat
      ) {
        new_move_arr.push(move_arr[0]);
        start = true;
      }
      continue;
    }
    if (
      line_item.lng == move_arr[move_arr.length - 1].lng &&
      line_item.lat == move_arr[move_arr.length - 1].lat
    ) {
      new_move_arr.push(move_arr[move_arr.length - 1]);
      break;
    }
    if (start) {
      for (let j = 1; j < move_arr.length - 1; j++) {
        if (
          line_item.lng == move_arr[j].lng &&
          line_item.lat == move_arr[j].lat
        ) {
          line_item = move_arr[j];
          line_item.is_orgi = true;
          break;
        }
      }

      if (
        new_move_arr[new_move_arr.length - 1].last_time <= line_item.last_time
      ) {
        new_move_arr.push(line_item);
      }
    }
  }
  line_index_i = i;
  return new_move_arr;
}
