import TrackIndex from "@/views/track/index.vue";
import { useRoute } from "vue-router/composables";
import { reactive } from "@vue/composition-api";
import { useVideoMonitor } from "@/hooks/videoMonitor";
import { useTrack } from "@/hooks/track";
import { basic_car_page, loc_car_gettrack, video_server_basic_camera, getUserDetail } from "@/api/projects/zhjg";
// import { trackData } from "@/api/mock";
import moment from "moment";

function useLeftPanel(track,params,car_info){
	let cur_params = params;
	const left_panel = reactive({
		car_list:[],
		is_show:false,
	});
	const clickItem = (item) => {
		left_panel.car_list.forEach(item =>{
			item.is_click = false;
		})
		item.is_click = true;
		cur_params = item;
		car_info.setInfo(item);
		track.loadData(item.id,item.com_id);
	}
	const changePanel = () => {
		left_panel.is_show = !left_panel.is_show;
	}
	left_panel.clickItem = clickItem;
	left_panel.changePanel = changePanel;
	const getList = () => {
		basic_car_page({
			_data_type: 'json',
		}).then(res =>{
			if(res.ok){
				left_panel.car_list = res.list.map(item =>{
					return {
						...item,
						// name:item.car_number,
						is_click:item.id == cur_params.id,
						
					}
				})
				
				if(!cur_params.id){
					if(res.list.length){
						left_panel.clickItem(left_panel.car_list[0]);
					}
				}
			}
		})
	}
	getList();
	return left_panel;
}

function userCarInfo(video_monitor,params){
	const car_info = reactive({
		info:{
			name:'苏M·724M6',
			luohu_time:'2022-12-12',
			jiankong:'车辆监控',
			zhuangkuang:'车辆状态良好',
		},
	});
	
	
	const setInfo = (item) => {
		getUserDetail({id: item.id}).then(res => {
			if (res.ok) {
				car_info.info = res
				car_info.info.last_locate_time = moment(res.last_locate_time * 1e3).format('YYYY-MM-DD HH:mm:ss')
			}
		})
		// car_info.info.name = item.name
		// car_info.info.luohu_time = item.registered_time;
		// car_info.info.jiankong = item.camera_name;
		// car_info.info.zhuangkuang = item.car_info;
		// let channels = (item.channels||[]).map((v,index)=>{
		// 	return {
		// 		name:index+1,
		// 		channel_id:v,
		// 		checked:false,
		// 	}
		// });
		// video_monitor.setVideoList(item,channels)
	}
	setInfo(params);
	car_info.setInfo = setInfo;
	return car_info;
}

const useRightPanel = () => {
	let right_panel =reactive({
		show:true,
	})
	const changePanel = () => {
		right_panel.show = !right_panel.show;
	}
	right_panel.changePanel = changePanel;
	return right_panel;
}
const previewApi = (deviceInfo,channel_item) => {
	return video_server_basic_camera({
		deviceID:deviceInfo.device_id,
		channel:channel_item.channel_id,
	})
}
export function useAll(params){
	console.log('params',params);
	let track = useTrack({
		uid:params.id,
		com_id:params.com_id,
		trackApi:loc_car_gettrack,//loc_car_gettrack
	});
	const video_monitor = useVideoMonitor(previewApi);
	const car_info = userCarInfo(video_monitor,params);
	
	const left_panel = useLeftPanel(track,params,car_info);
	const right_panel = useRightPanel();
	
	
	return {
		data:{
			left_panel,
			right_panel,
			car_info,
			video_monitor,
			track
		},
	}
}

export default {
components:{TrackIndex},
	data: function() {
		return {
		
		}
	},
	setup(props,{root}){
		const route = useRoute();
		let {data} = useAll(route.query);
		const {video_monitor:{video_ref},track:{track_ref}} = data;
		return {
			track_ref,
			data,
			video_ref,
		}
	},
	watch: {
	
	},
	mounted() {
		// this.init();
		// if(this.page_tag == 'track'){
		// 	this.PMC8O1J.datas[0].checked = true;
		// 	this.PMC8O1J.datas[1].checked = false;
		// }else{
		// 	this.PMC8O1J.datas[0].checked = false;
		// 	this.PMC8O1J.datas[1].checked = true;
		// }
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			this.PJqySQ1.content = '人员详情'
		},
		toBack(){
			// this.$router.back();
            if (this.$router) {
                this.$router.back()
            } else {
                this.$parent.close()
            }
		},
		
		
	}
}