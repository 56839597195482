
import TrackTimeline from "./track-timeline";
import PathCtrl from "./path-ctrl";
import TransformData from "./data/TransformData";
import TrackLayer from "./map/TrackLayer";
import {EventMarker} from "./map/EventMarker";
import moment from "moment";
import {trackData} from "./tool/trackMockData";
export default {
  name: "track-index",
  components: {PathCtrl, TrackTimeline},
  props:{
    timelineToEl:String
  },
  data(){
    return {
      trackTimeLineStatus:[],
      transformData:[],
      trackLayer:null,
      eventMarker:null,
      datetime:null,
      uid:0,
      com_id:0,
      show:true,
      has_load_data:false,
      track_res:{},
    }
  },
	beforeDestroy() {
		if (this.trackLayer) {
			//如果有就先清除
			this.trackLayer.clearTrack();
		}

		if (this.eventMarker) {
			this.eventMarker.destroy();
		}
	},
	methods:{
    setMap(map){
      this.map = map;
    },
	  setTrackApi(api){
		  this.trackApi = api;
	  },
    changeTime(v){
      this.datetime = v;
      this.loadData(this.uid,this.com_id);
    },
    setShow(is_show){
      this.show = is_show;
      if(this.has_load_data){
        this.renderData();
      }
    },
    async renderData(){
      if(!this.show){
        if (this.trackLayer) {
          //如果有就先清除
          this.trackLayer.clearTrack();
        }

        if (this.eventMarker) {
          this.eventMarker.destroy();
        }
      }else{

        if (this.trackLayer) {
          //如果有就先清除
          this.trackLayer.clearTrack();
        }
        this.trackLayer = new TrackLayer(this.transformData, this.map);
        await this.trackLayer.renderTrack();
        this.trackLayer.fitBounds();

        if (this.eventMarker) {
          this.eventMarker.destroy();
        }
        this.eventMarker = new EventMarker({
          data: this.trackTimeLineStatus,
          mapObj: this.map,
        });

        this.$refs.timeline.initDealTimeLine({
          transformData:this.transformData,
          trackTimeLineStatus:this.trackTimeLineStatus,
          trackLayer:this.trackLayer,
          track_res:this.track_res,
        });

        this.$refs.ctrl.init_progress_re_render({
          transformData:this.transformData,
          trackLayer:this.trackLayer,
          mapObj:this.map,
          track_res:this.track_res,
        });
      }
    },
    loadData(uid,com_id){
      let params = {};
      this.uid = uid;
      this.com_id = com_id;
      if(this.datetime){
        params.day = this.datetime.format('YYYYMMDD');
        params.start_time = moment(this.datetime.format('YYYY-MM-DD 00:00:00')).format('X');
	      params.daytime = params.start_time;
        params.end_time = params.start_time*1+86399;
      }
      this.has_load_data = false;

	    this.trackApi({
        uid:uid,
        com_id:com_id,
        ...params,
      }).then(res =>{
        if(res.ok){
          this.$refs.ctrl?.endPlay();
          this.track_res = res;
          this.has_load_data = true;
          this.trackTimeLineStatus = res.trackTimeLineStatus||[];

          this.transformData = new TransformData(res).handle();

          this.renderData();



        }
      })
    }
  },
}
