
/**
 * ~id TY0101
 * ~type 通用 按钮
 */
import {
    mixinObject
} from '@/cLibrary/utils/mixin';
import {
    getCssLinearColor
} from '@/cLibrary/utils/tool';
export default {
    name: "TY0101",
    mixins: [mixinObject],
    props: {
        dataType: {

            type: String,
            default: "Basic"
        },
        dataSetting: {
            type: Array,
            default () {
                return [{
                        key: "title",
                        name: "文本内容",
                        type: "input"
                    },
                    {
                        key: "checked",
                        name: "选中状态",
                        type: "checkbox"
                    },
                    {
                        key: "disabled",
                        name: "禁用状态",
                        type: "checkbox"
                    },
                    {
                        key: "isLogin",
                        name: "登录按钮",
                        type: "checkbox"
                    }
                ];
            }
        },
        title: {
            type: String,
            default: ""
        },
        checked: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isLogin: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            ce: {
                type: "TY",
                basic: {
                    name: "按钮",
                    width: 75,
                    height: 24
                },
                children: [{
                        key: "assets",
                        title: "资源替换",
                        children: [{
                            key: "imageSources",
                            title: "图片资源替换",
                            type: "panel",
                            children: [{
                                key: "images",
                                type: "upload",
                                children: [{
                                        name: "bg.png",
                                        value: "http://localhost:3000/static?filepath=Y%3A%5C%E7%BC%96%E8%BE%91%E5%99%A8%E6%AD%A3%E5%BC%8F%E7%89%88%E7%BC%93%E5%AD%98%5Cdsj_ce_web%5Csrc%5CcLibrary%5Cproject%5C%E6%95%B0%E5%AD%97%E5%9F%8E%E7%AE%A1-%E7%9B%91%E7%9D%A3%E6%99%BA%E6%85%A7%E4%B8%AD%E5%BF%83%5Cctese15rp86ll6q9w1vi%5Cctese15rp86ll6q9w1vi.vue&name=bg.png&v=0.5969073832505816",
                                        size: "75 x 24"
                                    },
                                    {
                                        name: "bg_cli.png",
                                        value: "http://localhost:3000/static?filepath=Y%3A%5C%E7%BC%96%E8%BE%91%E5%99%A8%E6%AD%A3%E5%BC%8F%E7%89%88%E7%BC%93%E5%AD%98%5Cdsj_ce_web%5Csrc%5CcLibrary%5Cproject%5C%E6%95%B0%E5%AD%97%E5%9F%8E%E7%AE%A1-%E7%9B%91%E7%9D%A3%E6%99%BA%E6%85%A7%E4%B8%AD%E5%BF%83%5Cctese15rp86ll6q9w1vi%5Cctese15rp86ll6q9w1vi.vue&name=bg_cli.png&v=0.4059980042314315",
                                        size: "75 x 24"
                                    },
                                    {
                                        name: "bg_hov.png",
                                        value: "http://localhost:3000/static?filepath=Y%3A%5C%E7%BC%96%E8%BE%91%E5%99%A8%E6%AD%A3%E5%BC%8F%E7%89%88%E7%BC%93%E5%AD%98%5Cdsj_ce_web%5Csrc%5CcLibrary%5Cproject%5C%E6%95%B0%E5%AD%97%E5%9F%8E%E7%AE%A1-%E7%9B%91%E7%9D%A3%E6%99%BA%E6%85%A7%E4%B8%AD%E5%BF%83%5Cctese15rp86ll6q9w1vi%5Cctese15rp86ll6q9w1vi.vue&name=bg_hov.png&v=0.3204708499949238",
                                        size: "75 x 24"
                                    },
                                    {
                                        name: "bg_che.png",
                                        value: "http://localhost:3000/static?filepath=Y%3A%5C%E7%BC%96%E8%BE%91%E5%99%A8%E6%AD%A3%E5%BC%8F%E7%89%88%E7%BC%93%E5%AD%98%5Cdsj_ce_web%5Csrc%5CcLibrary%5Cproject%5C%E6%95%B0%E5%AD%97%E5%9F%8E%E7%AE%A1-%E7%9B%91%E7%9D%A3%E6%99%BA%E6%85%A7%E4%B8%AD%E5%BF%83%5Cctese15rp86ll6q9w1vi%5Cctese15rp86ll6q9w1vi.vue&name=bg_che.png&v=0.09179589559013412",
                                        size: "75 x 24"
                                    },
                                    {
                                        name: "bg_dis.png",
                                        value: "http://localhost:3000/static?filepath=Y%3A%5C%E7%BC%96%E8%BE%91%E5%99%A8%E6%AD%A3%E5%BC%8F%E7%89%88%E7%BC%93%E5%AD%98%5Cdsj_ce_web%5Csrc%5CcLibrary%5Cproject%5C%E6%95%B0%E5%AD%97%E5%9F%8E%E7%AE%A1-%E7%9B%91%E7%9D%A3%E6%99%BA%E6%85%A7%E4%B8%AD%E5%BF%83%5Cctese15rp86ll6q9w1vi%5Cctese15rp86ll6q9w1vi.vue&name=bg_dis.png",
                                        size: "10 x 10"
                                    }
                                ]
                            }]
                        }]
                    },
                    {
                        key: "font",
                        title: "文字",
                        children: [{
                                title: "字板",
                                key: "font",
                                type: "panel",
                                children: [{
                                    title: "风格",
                                    key: "theme",
                                    type: "fontStyle",
                                    children: [{
                                        value: "常规蓝-PC"
                                    }]
                                }]
                            },
                            {
                                title: "文本",
                                key: "button",
                                type: "panel",
                                children: [{
                                        key: "align",
                                        title: "对齐方式",
                                        type: "radio",
                                        children: [{
                                                value: "flex-start",
                                                name: "左",
                                                checked: false
                                            },
                                            {
                                                value: "center",
                                                name: "中",
                                                checked: true
                                            },
                                            {
                                                value: "flex-end",
                                                name: "右",
                                                checked: false
                                            }
                                        ]
                                    },
                                    {
                                        key: "pd",
                                        type: "inputs",
                                        title: "文本内边距",
                                        tips: "上右下左",
                                        children: [{
                                                value: 0
                                            },
                                            {
                                                value: 0
                                            },
                                            {
                                                value: 0
                                            },
                                            {
                                                value: 0
                                            }
                                        ]
                                    },
                                    {
                                        key: "underline",
                                        title: "文本下划线",
                                        type: "switch",
                                        children: [{
                                            value: false
                                        }]
                                    },
                                    {
                                        key: "fontDef",
                                        title: "默认状态",
                                        type: "fontSelect",
                                        children: [{
                                            value: "C5"
                                        }]
                                    },
                                    {
                                        key: "fontCli",
                                        title: "点击状态",
                                        type: "fontSelect",
                                        children: [{
                                            value: "C4"
                                        }]
                                    },
                                    {
                                        key: "fontHov",
                                        title: "hover状态",
                                        type: "fontSelect",
                                        children: [{
                                            value: "C4"
                                        }]
                                    },
                                    {
                                        key: "fontChe",
                                        title: "选中状态",
                                        type: "fontSelect",
                                        children: [{
                                            value: "C4"
                                        }]
                                    },
                                    {
                                        key: "fontDis",
                                        title: "禁用状态",
                                        type: "fontSelect",
                                        children: [{
                                            value: "C4"
                                        }]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        key: "color",
                        title: "颜色",
                        children: [{
                            title: "色板",
                            key: "color",
                            type: "panel",
                            children: [{
                                title: "风格",
                                key: "theme",
                                type: "colorStyle",
                                children: [{
                                    value: "风格A"
                                }]
                            }]
                        }]
                    },
                    {
                        key: "icon",
                        title: "图标",
                        children: [{
                            title: "图标按钮",
                            key: "icon",
                            type: "panel",
                            disabled: true,
                            children: [{
                                    title: "风格配置",
                                    key: "theme",
                                    type: "panel",
                                    children: [{
                                            title: "主题",
                                            key: "theme",
                                            type: "iconTheme",
                                            children: [{
                                                value: "主题D"
                                            }]
                                        },
                                        {
                                            title: "尺寸",
                                            key: "size",
                                            type: "numberInputs",
                                            children: [{
                                                value: 16,
                                                min: 1,
                                                max: 96,
                                                suffix: "px"
                                            }]
                                        },
                                        {
                                            title: "线宽",
                                            key: "lineWidth",
                                            type: "slider",
                                            children: [{
                                                value: "2",
                                                min: 1,
                                                max: 10
                                            }]
                                        }
                                    ]
                                },
                                {
                                    key: "icon",
                                    title: "图标",
                                    type: "panel",
                                    children: [{
                                            key: "name",
                                            title: "名称",
                                            tips: "从IconPark复制",
                                            type: "iconPark",
                                            children: [{
                                                value: "setting-two"
                                            }]
                                        },
                                        {
                                            key: "borderColor",
                                            title: "外描边颜色",
                                            type: "color",
                                            children: [{
                                                value: "N13"
                                            }]
                                        },
                                        {
                                            key: "bgColor",
                                            title: "外填充颜色",
                                            type: "color",
                                            children: [{
                                                value: "P5"
                                            }]
                                        },
                                        {
                                            key: "borderColorInner",
                                            title: "内描边颜色",
                                            type: "color",
                                            children: [{
                                                value: "N14"
                                            }]
                                        },
                                        {
                                            key: "bgColorInner",
                                            title: "内填充颜色",
                                            type: "color",
                                            children: [{
                                                value: "O1"
                                            }]
                                        }
                                    ]
                                },
                                {
                                    key: "pr",
                                    title: "与文字间隔",
                                    type: "numberInputs",
                                    children: [{
                                        value: 3,
                                        suffix: "px"
                                    }]
                                }
                            ]
                        }]
                    },
                    {
                        key: "background",
                        title: "背景",
                        children: [{
                                key: "bgDef",
                                title: "默认状态",
                                type: "panel",
                                disabled: false,
                                children: [{
                                        key: "backgroundColor",
                                        title: "背景设置",
                                        type: "panel",
                                        disabled: true,
                                        children: [{
                                                key: "bgColor",
                                                title: "填充颜色",
                                                type: "color",
                                                children: [{
                                                    value: "P3"
                                                }]
                                            },
                                            {
                                                key: "borderColor",
                                                title: "描边颜色",
                                                type: "color",
                                                children: [{
                                                    value: "F2"
                                                }]
                                            },
                                            {
                                                key: "borderWidth",
                                                title: "描边线宽",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 1,
                                                    suffix: "px"
                                                }]
                                            },
                                            {
                                                key: "spaceWidth",
                                                title: "描边填充间隙",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 2,
                                                    suffix: "px"
                                                }]
                                            },
                                            {
                                                key: "borderRadius",
                                                title: "圆角",
                                                type: "inputs",
                                                children: [{
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    }
                                                ]
                                            },
                                            {
                                                key: "shadowColor",
                                                title: "投影颜色",
                                                type: "color",
                                                children: [{
                                                    value: "F26"
                                                }]
                                            },
                                            {
                                                key: "shadowBlur",
                                                title: "投影半径",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 1,
                                                    suffix: "px"
                                                }]
                                            }
                                        ]
                                    },
                                    {
                                        key: "backgroundImage",
                                        title: "背景图片",
                                        type: "panel",
                                        disabled: false,
                                        children: [{
                                            key: "pics",
                                            title: "背景图",
                                            type: "upload",
                                            children: [{
                                                name: "bg.png",
                                                size: "10 x 10",
                                                value: "http://localhost:3000/static?filepath=E%3A%5CCE%E6%AD%A3%E5%BC%8F%E7%89%88%5Cdsj_ce_web%5Csrc%5CcLibrary%5Cproject%5C%E6%95%B0%E5%AD%97%E5%9F%8E%E7%AE%A1-%E7%9B%91%E7%9D%A3%E6%99%BA%E6%85%A7%E4%B8%AD%E5%BF%83%5Cc8mdcxfm02bwl6d1v9tl%5Cc8mdcxfm02bwl6d1v9tl.vue&name=bg.png"
                                            }]
                                        }]
                                    }
                                ]
                            },
                            {
                                key: "bgCli",
                                title: "点击状态",
                                type: "panel",
                                disabled: false,
                                children: [{
                                        key: "backgroundColor",
                                        title: "背景设置",
                                        type: "panel",
                                        disabled: true,
                                        children: [{
                                                key: "bgColor",
                                                title: "填充颜色",
                                                type: "color",
                                                children: [{
                                                    value: "P3"
                                                }]
                                            },
                                            {
                                                key: "borderColor",
                                                title: "描边颜色",
                                                type: "color",
                                                children: [{
                                                    value: "F2"
                                                }]
                                            },
                                            {
                                                key: "borderWidth",
                                                title: "描边线宽",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 1,
                                                    suffix: "px"
                                                }]
                                            },
                                            {
                                                key: "spaceWidth",
                                                title: "描边填充间隙",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 2,
                                                    suffix: "px"
                                                }]
                                            },
                                            {
                                                key: "borderRadius",
                                                title: "圆角",
                                                type: "inputs",
                                                children: [{
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    }
                                                ]
                                            },
                                            {
                                                key: "shadowColor",
                                                title: "投影颜色",
                                                type: "color",
                                                children: [{
                                                    value: "F2"
                                                }]
                                            },
                                            {
                                                key: "shadowBlur",
                                                title: "投影半径",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 1,
                                                    suffix: "px"
                                                }]
                                            }
                                        ]
                                    },
                                    {
                                        key: "backgroundImage",
                                        title: "背景图片",
                                        type: "panel",
                                        disabled: false,
                                        children: [{
                                            key: "pics",
                                            title: "背景图",
                                            type: "upload",
                                            children: [{
                                                name: "bg_cli.png",
                                                size: "68 x 24",
                                                value: "http://localhost:3000/static?filepath=E%3A%5CCE%E6%AD%A3%E5%BC%8F%E7%89%88%5Cdsj_ce_web%5Csrc%5CcLibrary%5Cproject%5C%E6%95%B0%E5%AD%97%E5%9F%8E%E7%AE%A1-%E7%9B%91%E7%9D%A3%E6%99%BA%E6%85%A7%E4%B8%AD%E5%BF%83%5Cc8mdcxfm02bwl6d1v9tl%5Cc8mdcxfm02bwl6d1v9tl.vue&name=bg_cli.png&v=0.6368242915839919"
                                            }]
                                        }]
                                    }
                                ]
                            },
                            {
                                key: "bgHov",
                                title: "hover状态",
                                type: "panel",
                                disabled: false,
                                children: [{
                                        key: "backgroundColor",
                                        title: "背景设置",
                                        type: "panel",
                                        disabled: true,
                                        children: [{
                                                key: "bgColor",
                                                title: "填充颜色",
                                                type: "color",
                                                children: [{
                                                    value: "P3"
                                                }]
                                            },
                                            {
                                                key: "borderColor",
                                                title: "描边颜色",
                                                type: "color",
                                                children: [{
                                                    value: "F2"
                                                }]
                                            },
                                            {
                                                key: "borderWidth",
                                                title: "描边线宽",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 1,
                                                    suffix: "px"
                                                }]
                                            },
                                            {
                                                key: "spaceWidth",
                                                title: "描边填充间隙",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 2,
                                                    suffix: "px"
                                                }]
                                            },
                                            {
                                                key: "borderRadius",
                                                title: "圆角",
                                                type: "inputs",
                                                children: [{
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    }
                                                ]
                                            },
                                            {
                                                key: "shadowColor",
                                                title: "投影颜色",
                                                type: "color",
                                                children: [{
                                                    value: "F2"
                                                }]
                                            },
                                            {
                                                key: "shadowBlur",
                                                title: "投影半径",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 1,
                                                    suffix: "px"
                                                }]
                                            }
                                        ]
                                    },
                                    {
                                        key: "backgroundImage",
                                        title: "背景图片",
                                        type: "panel",
                                        disabled: false,
                                        children: [{
                                            key: "pics",
                                            title: "背景图",
                                            type: "upload",
                                            children: [{
                                                name: "bg_hov.png",
                                                size: "68 x 24",
                                                value: "http://localhost:3000/static?filepath=E%3A%5CCE%E6%AD%A3%E5%BC%8F%E7%89%88%5Cdsj_ce_web%5Csrc%5CcLibrary%5Cproject%5C%E6%95%B0%E5%AD%97%E5%9F%8E%E7%AE%A1-%E7%9B%91%E7%9D%A3%E6%99%BA%E6%85%A7%E4%B8%AD%E5%BF%83%5Cc8mdcxfm02bwl6d1v9tl%5Cc8mdcxfm02bwl6d1v9tl.vue&name=bg_hov.png&v=0.555058142289754"
                                            }]
                                        }]
                                    }
                                ]
                            },
                            {
                                key: "bgChe",
                                title: "选中状态",
                                type: "panel",
                                disabled: false,
                                children: [{
                                        key: "backgroundColor",
                                        title: "背景设置",
                                        type: "panel",
                                        disabled: true,
                                        children: [{
                                                key: "bgColor",
                                                title: "填充颜色",
                                                type: "color",
                                                children: [{
                                                    value: "P3"
                                                }]
                                            },
                                            {
                                                key: "borderColor",
                                                title: "描边颜色",
                                                type: "color",
                                                children: [{
                                                    value: "F2"
                                                }]
                                            },
                                            {
                                                key: "borderWidth",
                                                title: "描边线宽",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 1,
                                                    suffix: "px"
                                                }]
                                            },
                                            {
                                                key: "spaceWidth",
                                                title: "描边填充间隙",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 2,
                                                    suffix: "px"
                                                }]
                                            },
                                            {
                                                key: "borderRadius",
                                                title: "圆角",
                                                type: "inputs",
                                                children: [{
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    }
                                                ]
                                            },
                                            {
                                                key: "shadowColor",
                                                title: "投影颜色",
                                                type: "color",
                                                children: [{
                                                    value: "F2"
                                                }]
                                            },
                                            {
                                                key: "shadowBlur",
                                                title: "投影半径",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 1,
                                                    suffix: "px"
                                                }]
                                            }
                                        ]
                                    },
                                    {
                                        key: "backgroundImage",
                                        title: "背景图片",
                                        type: "panel",
                                        disabled: false,
                                        children: [{
                                            key: "pics",
                                            title: "背景图",
                                            type: "upload",
                                            children: [{
                                                name: "bg_che.png",
                                                size: "68 x 24",
                                                value: "http://localhost:3000/static?filepath=E%3A%5CCE%E6%AD%A3%E5%BC%8F%E7%89%88%5Cdsj_ce_web%5Csrc%5CcLibrary%5Cproject%5C%E6%95%B0%E5%AD%97%E5%9F%8E%E7%AE%A1-%E7%9B%91%E7%9D%A3%E6%99%BA%E6%85%A7%E4%B8%AD%E5%BF%83%5Cc8mdcxfm02bwl6d1v9tl%5Cc8mdcxfm02bwl6d1v9tl.vue&name=bg_che.png&v=0.19331830343847556"
                                            }]
                                        }]
                                    }
                                ]
                            },
                            {
                                key: "bgDis",
                                title: "禁用状态",
                                type: "panel",
                                disabled: false,
                                children: [{
                                        key: "backgroundColor",
                                        title: "背景设置",
                                        type: "panel",
                                        disabled: false,
                                        children: [{
                                                key: "bgColor",
                                                title: "填充颜色",
                                                type: "color",
                                                children: [{
                                                    value: "P3"
                                                }]
                                            },
                                            {
                                                key: "borderColor",
                                                title: "描边颜色",
                                                type: "color",
                                                children: [{
                                                    value: "F2"
                                                }]
                                            },
                                            {
                                                key: "borderWidth",
                                                title: "描边线宽",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 1,
                                                    suffix: "px"
                                                }]
                                            },
                                            {
                                                key: "spaceWidth",
                                                title: "描边填充间隙",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 2,
                                                    suffix: "px"
                                                }]
                                            },
                                            {
                                                key: "borderRadius",
                                                title: "圆角",
                                                type: "inputs",
                                                children: [{
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    },
                                                    {
                                                        value: 3
                                                    }
                                                ]
                                            },
                                            {
                                                key: "shadowColor",
                                                title: "投影颜色",
                                                type: "color",
                                                children: [{
                                                    value: "F2"
                                                }]
                                            },
                                            {
                                                key: "shadowBlur",
                                                title: "投影半径",
                                                type: "numberInputs",
                                                children: [{
                                                    value: 1,
                                                    suffix: "px"
                                                }]
                                            }
                                        ]
                                    },
                                    {
                                        key: "backgroundImage",
                                        title: "背景图片",
                                        type: "panel",
                                        disabled: false,
                                        children: [{
                                            key: "pics",
                                            title: "背景图",
                                            type: "upload",
                                            children: [{
                                                name: "bg_dis.png",
                                                size: "10 x 10",
                                                value: "http://localhost:3000/static?filepath=E%3A%5CCE%E6%AD%A3%E5%BC%8F%E7%89%88%5Cdsj_ce_web%5Csrc%5CcLibrary%5Cproject%5C%E6%95%B0%E5%AD%97%E5%9F%8E%E7%AE%A1-%E7%9B%91%E7%9D%A3%E6%99%BA%E6%85%A7%E4%B8%AD%E5%BF%83%5Cc8mdcxfm02bwl6d1v9tl%5Cc8mdcxfm02bwl6d1v9tl.vue&name=bg_dis.png"
                                            }]
                                        }]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            status: "Def",
            loading: 0
        };
    },
    watch: {
        checked() {
            this.init();
        },
        disabled() {
            this.init();
        }
    },
    computed: {
        width() {
            return this.ce.basic.width;
        },
        height() {
            return this.ce.basic.height;
        },
        fontTheme() {
            return this.$getValueByCe([
                'font',
                'font',
                'theme'
            ]);
        },
        colorTheme() {
            return this.$getValueByCe([
                'color',
                'color',
                'theme'
            ]);
        },
        disabledDefault() {
            const disabled = this.$getValueByCe([
                'background',
                'bgDef'
            ], 'disabled');
            return disabled;
        },
        disabledClick() {
            const disabled = this.$getValueByCe([
                'background',
                'bgCli'
            ], 'disabled');
            return disabled;
        },
        disabledHover() {
            const disabled = this.$getValueByCe([
                'background',
                'bgHov'
            ], 'disabled');
            return disabled;
        },
        boxStyle() {
            const name = 'bg' + this.status;
            const disabled1 = this.$getValueByCe([
                'background',
                name
            ], 'disabled');
            const disabled2 = this.$getValueByCe([
                'background',
                name,
                'backgroundColor'
            ], 'disabled');
            const disabled3 = this.$getValueByCe([
                'background',
                name,
                'backgroundImage'
            ], 'disabled');
            const borderColor = this.$getValueByCe([
                'background',
                name,
                'backgroundColor',
                'borderColor'
            ]);
            const borderWidth = this.$getValueByCe([
                'background',
                name,
                'backgroundColor',
                'borderWidth'
            ]);
            const spaceWidth = this.$getValueByCe([
                'background',
                name,
                'backgroundColor',
                'spaceWidth'
            ]);
            const borderRadius = this.$getValueByCe([
                'background',
                name,
                'backgroundColor',
                'borderRadius'
            ], 'array');
            const shadowColor = this.$getValueByCe([
                'background',
                name,
                'backgroundColor',
                'shadowColor'
            ]);
            const shadowBlur = this.$getValueByCe([
                'background',
                name,
                'backgroundColor',
                'shadowBlur'
            ]);
            const cursor = this.disabled ? 'no-drop' : 'pointer';
            const res = {
                borderColor: this.$getColor(borderColor),
                shadowColor: this.$getColor(shadowColor),
                borderWidth: borderWidth + 'px',
                spaceWidth: spaceWidth + 'px',
                borderRadius: borderRadius.join('px ') + 'px',
                shadowBlur: shadowBlur + 'px'
            };
            const style = {
                cursor
            };
            const imgs = {
                bgDef: require('./theme/bg.png'),
                bgCli: require('./theme/bg_cli.png'),
                bgHov: require('./theme/bg_hov.png'),
                bgChe: require('./theme/bg_che.png'),
                bgDis: require('./theme/bg_dis.png')
            };
            if (!disabled1) {
                if (!disabled2) {
                    style.border = `${ res.borderWidth } solid ${ res.borderColor.value[0] }`;
                    style.boxShadow = `0 0 ${ res.shadowBlur } ${ res.shadowColor.value[0] }`;
                    style.padding = res.spaceWidth;
                    style.borderRadius = res.borderRadius;
                }
                if (!disabled3) {
                    style.backgroundImage = `url(${ imgs[name] })`;
                }
            }
            return {
                width: this.width + 'px',
                height: this.height + 'px',
                ...style
            };
        },
        wrapStyle() {
            const name = 'bg' + this.status;
            const disabled1 = this.$getValueByCe([
                'background',
                name
            ], 'disabled');
            const disabled2 = this.$getValueByCe([
                'background',
                name,
                'backgroundColor'
            ], 'disabled');
            const bgColor = this.$getValueByCe([
                'background',
                name,
                'backgroundColor',
                'bgColor'
            ]);
            const borderWidth = this.$getValueByCe([
                'background',
                name,
                'backgroundColor',
                'borderWidth'
            ]);
            const spaceWidth = this.$getValueByCe([
                'background',
                name,
                'backgroundColor',
                'spaceWidth'
            ]);
            const borderRadius = this.$getValueByCe([
                'background',
                name,
                'backgroundColor',
                'borderRadius'
            ], 'array');
            const align = this.$getValueByCe([
                'font',
                'button',
                'align'
            ], 'select');
            const res = {
                bgColor: this.$getColor(bgColor),
                borderRadius: borderRadius.map(el => {
                    return el * 0.9;
                }).join('px ') + 'px'
            };
            const bg = getCssLinearColor(res.bgColor.angle, res.bgColor.value);
            const style = {
                justifyContent: align
            };
            if (!disabled1 && !disabled2) {
                style.borderRadius = res.borderRadius;
                style.width = this.width - borderWidth * 2 - spaceWidth * 2 + 'px';
                style.height = this.height - borderWidth * 2 - spaceWidth * 2 + 'px';
                if (bg.match('linear-gradient')) {
                    style.backgroundImage = bg;
                } else {
                    style.backgroundColor = bg;
                }
            } else {
                style.width = this.width + 'px';
                style.height = this.height + 'px';
            }
            return style;
        },
        iconStyle() {
            const pr = this.$getValueByCe([
                'icon',
                'icon',
                'pr'
            ]);
            return {
                paddingRight: pr + 'px'
            };
        },
        textStyle() {
            const pd = this.$getValueByCe([
                'font',
                'button',
                'pd'
            ], 'array');
            const underline = this.$getValueByCe([
                'font',
                'button',
                'underline'
            ]);
            const style = {};
            const font = this.$getFont(this.buttonFontName);
            if (underline) {
                style.borderBottom = `1px solid ${ font.color[0] }`;
            }
            return {
                padding: pd.join('px ') + 'px',
                ...style
            };
        },
        buttonFontName() {
            const name = 'font' + this.status;
            const buttonFontName = this.$getValueByCe([
                'font',
                'button',
                name
            ]);
            return buttonFontName;
        },
        theme() {
            const size = this.$getValueByCe([
                'icon',
                'icon',
                'theme',
                'theme'
            ]);
            return size;
        },
        size() {
            const size = this.$getValueByCe([
                'icon',
                'icon',
                'theme',
                'size'
            ]);
            return size;
        },
        borderWidth() {
            const borderWidth = this.$getValueByCe([
                'icon',
                'icon',
                'theme',
                'lineWidth'
            ]);
            return borderWidth;
        },
        icon() {
            const disabled = this.$getValueByCe([
                'icon',
                'icon'
            ], 'disabled');
            if (disabled) {
                return {};
            }
            const name = this.$getValueByCe([
                'icon',
                'icon',
                'icon',
                'name'
            ]);
            const stroke = this.$getValueByCe([
                'icon',
                'icon',
                'icon',
                'borderColor'
            ]);
            const fill = this.$getValueByCe([
                'icon',
                'icon',
                'icon',
                'bgColor'
            ]);
            const strokeInner = this.$getValueByCe([
                'icon',
                'icon',
                'icon',
                'borderColorInner'
            ]);
            const fillInner = this.$getValueByCe([
                'icon',
                'icon',
                'icon',
                'bgColorInner'
            ]);
            const borderColor = this.$getColor(stroke);
            const bgColor = this.$getColor(fill);
            const borderColorInner = this.$getColor(strokeInner);
            const bgColorInner = this.$getColor(fillInner);
            const icon = {
                name
            };
            icon.fill = [
                borderColor.value[0],
                bgColor.value[0],
                borderColorInner.value[0],
                bgColorInner.value[0]
            ];
            return icon;
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.$forceUpdate();
            if (this.disabled) {
                this.status = 'Dis';
            } else if (this.checked) {
                this.status = 'Che';
            } else {
                this.status = 'Def';
            }
            if (this.isLogin) {
                this.loading = 1;
            } else {
                this.loading = 0;
            }
            this.$$log(this.ce);
        },
        onClick() {
            if (this.disabled || this.checked) {
                return false;
            }
            if (this.isLogin) {
                this.loading = 2;
            }
            this.$emit('click');
        },
        mousedown() {
            if (this.disabled || this.checked || this.disabledClick) {
                return false;
            }
            this.status = 'Cli';
        },
        mouseup() {
            if (this.disabled || this.checked) {
                return false;
            }
            if (this.disabledHover) {
                this.status = 'Def';
            } else {
                this.status = 'Hov';
            }
        },
        mouseenter() {
            if (this.disabled || this.checked || this.disabledHover) {
                return false;
            }
            this.status = 'Hov';
        },
        mouseleave() {
            if (this.disabled || this.checked) {
                return false;
            }
            this.status = 'Def';
        }
    }
}; 
