export default function formatSeconds(value) {
  var theTime = parseInt(value); // 秒
  var middle = 0; // 分
  var hour = 0; // 小时

  if (theTime > 60) {
    middle = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (middle > 60) {
      hour = parseInt(middle / 60);
      middle = parseInt(middle % 60);
    }
  }
  var result = "";
  // var result = ""+parseInt(theTime)+"秒";
  if (middle > 0) {
    result = "" + parseInt(middle) + "分钟" + result;
  }
  if (hour > 0) {
    result = "" + parseInt(hour) + "小时" + result;
  }
  if (middle == 0 && hour == 0) {
    result = parseInt(theTime) + "秒";
  }
  return result;
}
