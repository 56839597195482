let is_multi_day = false;
export default function switchTS2time(timestamp, n) {
  var T = new Date(timestamp * 1000);
  var year = T.getFullYear();
  var month = T.getMonth() + 1;
  var day = T.getDate();
  var hour = T.getHours();
  var minute = T.getMinutes();
  var second = T.getSeconds();
  if (minute < 10) {
    minute = "0" + minute;
  }
  if (second < 10) {
    second = "0" + second;
  }
  if (n == 1) {
    return (
      year +
      "-" +
      month +
      "-" +
      day +
      "&nbsp" +
      hour +
      ":" +
      minute +
      ":" +
      second
    );
  } else if (n == 2) {
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      month = "0" + day;
    }
    if (hour < 10) {
      hour = "0" + hour;
    }
    return year + "-" + month + "-" + day + " " + hour + ":" + minute;
  } else if (n == 3) {
    if (is_multi_day) {
      return month + "-" + day + "&nbsp;";
    }
    return "";
  } else if (n == 4) {
    return hour + ":" + minute;
  } else if (n == 5) {
    return year + "-" + month + "-" + day + " " + hour + ":" + minute;
  } else {
    return hour + ":" + minute + ":" + second;
  }
}
