
import cackd4f0omdll9xxdiq7 from '@/cLibrary/project/绵阳高新/cackd4f0omdll9xxdiq7/cackd4f0omdll9xxdiq7.vue';
import cewldhi4q9mslpkw1ni3 from '@/cLibrary/project/重庆大足/cewldhi4q9mslpkw1ni3/cewldhi4q9mslpkw1ni3.vue';
import c4hvta6bka0ilc8rl3e7 from '@/cLibrary/project/重庆大足/c4hvta6bka0ilc8rl3e7/c4hvta6bka0ilc8rl3e7.vue';
import cl3ly16yeoxlc8rtdii from '@/cLibrary/project/重庆大足/cl3ly16yeoxlc8rtdii/cl3ly16yeoxlc8rtdii.vue';
import cplt332meiyhlc8wx013 from '@/cLibrary/project/重庆大足/cplt332meiyhlc8wx013/cplt332meiyhlc8wx013.vue';
import cwwu8kd1jt2jlmudbazi from '@/cLibrary/project/重庆大足/cwwu8kd1jt2jlmudbazi/cwwu8kd1jt2jlmudbazi.vue';
import cwmvoqwqx53rlmvsyg4f from '@/cLibrary/project/重庆大足/cwmvoqwqx53rlmvsyg4f/cwmvoqwqx53rlmvsyg4f.vue';
import ct0vz27fflshlrpupoiq from '@/cLibrary/project/海安市/ct0vz27fflshlrpupoiq/ct0vz27fflshlrpupoiq.vue';
import mixins from './service';

export default {
	name: 'huanweirenyuanxiangqingzuoyefenxi-PSZs',
	mixins,
	data: function() {
		return {
			images: {
				renyuanjibenxinxi_PLXYOVs: `${window.baseUrl}oss-images/20240123/1706001126PjLc.png`,
				pL_PQudgar: `${window.baseUrl}oss-images/20240229/1709190698PLJP.png`,
				riqishaixuan_PEGDXV2: `${window.baseUrl}oss-images/20230924/1695553070PLhR.png`,
				guiji_PAuLxrZ: `${window.baseUrl}oss-images/20230924/1695553129P8fE.png`,
				pR_PHRmU3m: `${window.baseUrl}oss-images/20240123/1706002547PwCu.png`,
				bofangtiao_PsKSMw5: `${window.baseUrl}oss-images/20221225/1671958065PvGm.png`,
				rongqizujian_PANcQhD: `${window.baseUrl}oss-images/20221229/1672308970PxhV.png`,
				top_PCtk4no: `${window.baseUrl}oss-images/20240130/1706595936PQmN.png`,
				xiaoxiangxuanzhong_Pe5eCCL: `${window.baseUrl}oss-images/20240123/1706002352PxNG.png`,
				cebianfuchuang_PdrTSTS: `${window.baseUrl}oss-images/20240123/1706002201PTUK.png`,
				genjiedian_P0J21Si: `${window.baseUrl}oss-images/20221224/1671866580PWop.png`,
			},
			PTdBwE3: {
				"title": "基础信息"
			},
			P2Cx19N: {
				"content": "贾余杰（保洁员）"
			},
			POoYmIR: {
				"content": "女"
			},
			PctDTaq: {
				"content": "性别："
			},
			P0IihhO: {
				"content": "演示人员"
			},
			PGQ7cdV: {
				"content": "人员角色："
			},
			PVJInxQ: {
				"content": "XXXXXXXXXX"
			},
			PcNFCP7: {
				"content": "所属单位："
			},
			P2KR4TV: {
				"content": "XXXXXXXXXX"
			},
			P4ZQl3T: {
				"content": "手机号码："
			},
			P89URW1: {
				"content": "XXXXX"
			},
			PoNQ6If: {
				"content": "部门："
			},
			PNlp7Jt: {
				"content": "2022-12-11  21:34:28"
			},
			PNaY0Ed: {
				"content": "定位时间："
			},
			PjCO0ka: {
				"content": "XXXXXXXXXXXXXXXXXXXXXXXXXX"
			},
			PlIdYPj: {
				"content": "当前位置："
			},
			P9P9Q6S: {
				"title": "查看人员列表",
				"checked": false,
				"disabled": false,
				"isLogin": false
			},
			PQFK7mc: {
				"content": "轨迹时间轴"
			},
			Pjtgxck: {
				"datas": [{
					"name": "今日",
					"value": 1,
					"checked": true
				}, {
					"name": "前一天",
					"value": 1,
					"checked": false
				}]
			},
			P3HFygy: {
				"content": "人员工作轨迹"
			},
			PTScLy0: {
				"title": "镜头俯视",
				"checked": true,
				"disabled": false,
				"isLogin": false
			},
			PlpuITt: {
				"title": "镜头跟随",
				"checked": false,
				"disabled": false,
				"isLogin": false
			},
			PeI4SHI: {
				"title": "",
				"checked": false,
				"disabled": false,
				"isLogin": false
			},
			PZDTDrL: {
				"datas": [{
					"name": "本月",
					"value": 1,
					"checked": true
				}, {
					"name": "上月",
					"value": 1,
					"checked": false
				}]
			},
			PcUTxPO: {
				"title": "",
				"checked": false,
				"disabled": false,
				"isLogin": false
			},
			PJqySQ1: {
				"content": "环卫人员作业分析"
			},
			PFz7Z3s: {
				"content": "人员名称"
			},
			PgYz6jT: {
				"content": "人员名称"
			},
		}
	},
	methods: {
		getBackground(name) {
			return {
				backgroundImage: `url(${this.images[name]})`
			}
		},

	},
	components: {
		cackd4f0omdll9xxdiq7,
		cewldhi4q9mslpkw1ni3,
		c4hvta6bka0ilc8rl3e7,
		cl3ly16yeoxlc8rtdii,
		cplt332meiyhlc8wx013,
		cwwu8kd1jt2jlmudbazi,
		cwmvoqwqx53rlmvsyg4f,
		ct0vz27fflshlrpupoiq,
	},
}
